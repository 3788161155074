import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  MenuConfig,
  MenuOption,
  OptionConfig,
  SectionConfig,
} from '../navigation.model';
import { NavigationService } from '../navigation.service';
import { SubNavOptionComponent } from './subnav-option/subnav-option.component';
import { WashListService } from 'src/app/dashboard/wash-list/wash-list.service';
import { getSectionDict } from '../navigation.config';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-subnav',
  standalone: true,
  imports: [SubNavOptionComponent],
  templateUrl: './subnav.component.html',
  styleUrl: './subnav.component.scss',
})
export class SubNavComponent implements OnInit, AfterViewChecked {
  private isTabActive = true;
  roleCountIntervalId = null;

  @Input() isMobile: boolean;
  @Input() isVisible: boolean;
  @Input() isDesktop: boolean;
  @Input() isPortrait: boolean;
  @Input() menuConfig: MenuConfig;
  @Input() isDesktopExpanded: boolean;
  @Input() hasQualaRole: () => boolean;
  @Input() hasSubnavPermission: boolean;
  @Input() sectionSelected$: Observable<string>;

  @Output() setSectionSelected = new EventEmitter<string>();
  @Output() itemChosen = new EventEmitter<
    SectionConfig | OptionConfig | string
  >();

  currentSubSystemSection: SectionConfig | OptionConfig | undefined;

  constructor(
    private authService: AuthService,
    private menuService: NavigationService,
    private washListService: WashListService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.setSectionOptions();
    window.onblur = () => {
      this.isTabActive = false;
    };
    window.onfocus = () => {
      this.isTabActive = true;
    };
    this.initializeUserRoleItemCounts();

    this.authService.user$.subscribe((user) => {
      if (user) this.refetchDynamicContent();
    });

    this.menuConfig.sections.forEach((section) => {
      if (section.value === 'wash-list') {
        this.washListService.countMap.subscribe((countMap) => {
          section.options.forEach((subItem) => {
            if (countMap.total[subItem.value] !== undefined) {
              subItem.totalCount = countMap.total[subItem.value];
            }
            if (countMap.new[subItem.value] !== undefined) {
              subItem.newCount = countMap.new[subItem.value];
            }
          });
        });
      }
    });
  }

  onItemChosen($event: SectionConfig | OptionConfig): void {
    this.itemChosen.emit($event);
  }

  getSectionLabel() {
    return this.currentSubSystemSection?.label?.toUpperCase();
  }

  showMinimalistSubnav(): boolean {
    return !this.isDesktopExpanded && this.isDesktop;
  }

  setSectionOptions() {
    this.sectionSelected$.subscribe((section) => {
      section
        ? (this.currentSubSystemSection =
            this.menuService.getCurrentSectionOptions(section, this.menuConfig))
        : (this.currentSubSystemSection =
            this.menuService.getMenuSectionThroughUrl(this.menuConfig));
    });
  }

  private initializeUserRoleItemCounts() {
    this.authService.isAuthorized().then((isAuthenticated) => {
      if (isAuthenticated) {
        const oneMinuteInMilliseconds = 60000;
        this.getAllWashItemsCountForUserRole();
        this.roleCountIntervalId = setInterval(() => {
          this.getAllWashItemsCountForUserRole();
        }, oneMinuteInMilliseconds * 3);
      }
    });
  }

  private refetchDynamicContent() {
    if (this.roleCountIntervalId) {
      clearInterval(this.roleCountIntervalId);
      this.roleCountIntervalId = null;
    }
    this.initializeUserRoleItemCounts();
  }

  getAllWashItemsCountForUserRole() {
    if (this.hasQualaRole) {
      this.washListService.refreshCount([
        MenuOption.Draft,
        MenuOption.NewWashRequests,
        MenuOption.Pending,
        MenuOption.Accepted,
        MenuOption.NeedsAction,
        MenuOption.InProgress,
        MenuOption.Hold,
        MenuOption.Completed,
        MenuOption.Canceled,
        MenuOption.Rejected,
        MenuOption.CreditHold,
        MenuOption.Expired,
        MenuOption.SchneiderPortalCompletions,
      ]);
    } else {
      this.washListService.refreshCount([
        MenuOption.Draft,
        MenuOption.Submitted,
        MenuOption.NeedsAction,
        MenuOption.Accepted,
        MenuOption.Pending,
        MenuOption.InProgress,
        MenuOption.Completed,
        MenuOption.Canceled,
        MenuOption.Rejected,
      ]);
    }
  }

  getImportantItemsCountForQualaRole() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.NewWashRequests,
      MenuOption.Pending,
      MenuOption.NeedsAction,
    ]);
  }

  getNormalItemsCountForQualaRole() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Accepted,
      MenuOption.InProgress,
      MenuOption.Hold,
      MenuOption.CreditHold,
    ]);
  }

  getImportantItemsCountForDispatcher() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Submitted,
      MenuOption.Accepted,
    ]);
  }

  getNormalItemsCountForDispatcher() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.NeedsAction,
      MenuOption.Pending,
      MenuOption.InProgress,
    ]);
  }

  getLowPriorityItemsCount() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Draft,
      MenuOption.Completed,
      MenuOption.Canceled,
      MenuOption.Rejected,
    ]);
  }
}
