import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Warehouse } from '../../../core/model/warehouse.model';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NcrService } from '../ncr.service';
import { User } from '../../../core/model/user.model';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import {
  ActionsBarProperties,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';

@Component({
  selector: 'app-assign-overlay',
  templateUrl: './assign-overlay.component.html',
  styleUrls: ['./assign-overlay.component.scss'],
})
export class AssignOverlayComponent implements OnInit, OnDestroy {
  assignForm: UntypedFormGroup;
  warehouseOptions$: Observable<Warehouse[]>;
  lineOfBusinessOptions: string[] = [
    'Depot',
    'IBC',
    'Industrial Services',
    'Maintenance',
    'Rail & Specialty',
    'Tank Wash',
    'Transportation',
  ];

  wareHouseWithRegion: Array<any>;
  loadingUserOptions = false;
  triedSubmit: boolean;
  isReassignment: boolean;
  title: string;
  assigneeSelectorText: string;
  allManagers: Array<User>;
  terminalNumber: string;
  allManagersCheck: boolean;
  regularUsers: Array<User>;
  allManagersCheckSubscription: Subscription;
  managersSubscription: Subscription;
  allWarehouseSubscription: Subscription;
  loadingAllManagers: boolean;
  warehousesMap = {};
  formattedWarehouses = [];
  terminalLinesOfBusiness = [];
  lineOfBusinessIsReadOnly: boolean = false;
  actionsBarConfig: ActionsBarProperties = {} as any;
  formStatusChangesSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AssignOverlayComponent>,
    private ncrService: NcrService,
    private warehouseService: WarehouseService
  ) {
    this.isReassignment = data.isReassignment ? data.isReassignment : false;
    this.terminalNumber = data.terminalNumber;
  }

  ngOnDestroy(): void {
    this.managersSubscription.unsubscribe();
    this.allManagersCheckSubscription.unsubscribe();
    this.allWarehouseSubscription.unsubscribe();
    this.formStatusChangesSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.allWarehouseSubscription =
      this.warehouseService.allWarehouses$.subscribe((warehouses) => {
        ({ warehousesMap: this.warehousesMap } =
          this.ncrService.buildWarehouseData(warehouses));
      });

    this.managersSubscription = this.ncrService.managers$.subscribe(
      (managers) => {
        managers.forEach((manager) => {
          manager.displayInfo = this.createDisplayInfo(manager);
        });
        this.allManagers = this.sortManagersByName(managers);
        this.loadingAllManagers = false;
      }
    );

    this.lineOfBusinessOptions = this.lineOfBusinessOptions.sort();
    this.title = this.isReassignment ? 'Return NCR to Analysis' : 'Assign NCR';
    this.assigneeSelectorText = this.isReassignment
      ? 'Reassign To *'
      : 'Assign To *';
    this.initializeForm(this.isReassignment);
  }

  private createDisplayInfo(user) {
    if (user.name) {
      return `${user.name} | ${user.terminalDisplay}`;
    }
    return `${user.username} | ${user.terminalDisplay}`;
  }

  findLineOfBusinessByTerminalNumber(terminalNumber: string): string[] {
    const warehouseEntry = (
      Object.values(this.warehousesMap) as Warehouse[]
    ).find((entry) => entry.terminalNumber === terminalNumber);

    return warehouseEntry
      ? warehouseEntry.linesOfBusiness
      : this.lineOfBusinessOptions;
  }

  initializeLineOfBusinessWithSingleItem() {
    const line = this.terminalLinesOfBusiness[0];
    this.assignForm.controls.lineOfBusiness.setValue(line);
    this.lineOfBusinessIsReadOnly = true;
  }

  private initializeForm(isReassignment: boolean) {
    this.assignForm = new UntypedFormGroup({
      assignedUser: new UntypedFormControl('', Validators.required),
      lineOfBusiness: new UntypedFormControl(
        '',
        isReassignment ? null : Validators.required
      ),
      reassignComments: new UntypedFormControl(''),
      allManagersCheck: new UntypedFormControl(''),
    });

    this.loadingUserOptions = true;
    this.assignForm.controls.assignedUser.setValue('');
    this.assignForm.controls.allManagersCheck.setValue(false);

    this.subscribeToAllManagersCheckChanges();

    this.terminalLinesOfBusiness = this.findLineOfBusinessByTerminalNumber(
      this.terminalNumber?.trim()
    );

    if (this.terminalLinesOfBusiness.length === 1) {
      this.initializeLineOfBusinessWithSingleItem();
    }

    this.ncrService
      .getUsersFromWarehouse(this.terminalNumber?.trim())
      .then((result) => {
        result.forEach((user) => {
          user.displayInfo = this.createDisplayInfo(user);
        });
        this.regularUsers = result;
        this.loadingUserOptions = false;
        this.initActionsBarConfigs();
      })
      .catch(() => {
        this.regularUsers = [];
        this.loadingUserOptions = false;
        this.initActionsBarConfigs();
      });
  }

  subscribeToAllManagersCheckChanges() {
    this.allManagersCheckSubscription =
      this.assignForm.controls.allManagersCheck.valueChanges.subscribe(
        (checked: boolean) => {
          this.allManagersCheck = checked;
          if (checked) {
            this.loadingAllManagers = this.allManagers.length === 0;
          } else {
            this.loadingAllManagers = false;
          }
        }
      );
  }

  getUserName(id: string) {
    const users = [...this.allManagers, ...this.regularUsers];
    return users.find((user) => user.id === id).username;
  }

  initActionsBarConfigs() {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnClick: () => this.closeOverlay(),
          btnId: 'cancel',
          btnText: 'Cancel',
          icon: 'close',
          buttonStyle: ButtonStyle.SECONDARY,
        },
        {
          btnClick: () => this.submit(),
          btnText: 'Submit',
          icon: 'arrow_forward',
          buttonStyle: ButtonStyle.PRIMARY,
          btnId: 'submit',
          btnDisabled: this.loadingUserOptions || !this.assignForm.valid,
        },
      ],
    };
    this.formStatusChangesSubscription =
      this.assignForm.statusChanges.subscribe((status) => {
        this.actionsBarConfig.buttons[1].btnDisabled =
          status !== 'VALID' || this.loadingUserOptions;
      });
  }

  submit = () => {
    this.triedSubmit = true;

    const assignToId = this.assignForm.value.assignedUser;
    const assignToUserName = this.getUserName(assignToId);
    const lineOfBusiness = this.assignForm.value.lineOfBusiness;
    const reassignComments = this.assignForm.value.reassignComments;

    this.dialogRef.close({
      assignedUser: assignToUserName,
      assignedUserId: assignToId,
      lineOfBusiness,
      reassignComments,
    });
  };

  closeOverlay = () => {
    this.dialogRef.close();
  };

  sortManagersByName(managers) {
    return managers.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
}
