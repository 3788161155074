import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-row',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './row.component.html',
  styleUrl: './row.component.scss',
})
export class RowComponent implements OnChanges {
  @Input() columns: {
    label: string;
    fieldName: string;
    fieldAlias: string;
    asc?: boolean;
    dateTimeConfig?: {
      format: string;
      timezone: string;
    };
  }[] = [];
  @Input() item = {};
  @Input() rowHeaderConfig: {
    warnRow?: boolean;
    value: any;
    label: string;
    iconConfig: {
      iconName: string;
      iconTooltip?: string;
      iconType: 'warn' | 'info';
    }[];
  };
  @Output() showDetailsEvent = new EventEmitter<any>();
  showDetails = false;
  headerData = {};

  ngOnChanges(changes: SimpleChanges): void {
    for (const [, config] of Object.entries(this.rowHeaderConfig || {})) {
      this.headerData[`${config.label}`] = {
        value: config.value,
        iconConfig: config.iconConfig,
        warnRow: config.warnRow,
      };
    }
    const rowHeaderConfigCurrent = changes?.rowHeaderConfig?.currentValue;
    const rowHeaderConfigPrevious = changes?.rowHeaderConfig?.previousValue;

    if (rowHeaderConfigCurrent && rowHeaderConfigPrevious) {
      for (const key of Object.keys(rowHeaderConfigCurrent)) {
        if (
          rowHeaderConfigCurrent &&
          rowHeaderConfigPrevious &&
          rowHeaderConfigCurrent[key]?.value !==
            rowHeaderConfigPrevious[key].value
        ) {
          this.showDetails = false;
        }
      }
    }
  }

  toggleExpand() {
    this.showDetails = !this.showDetails;
    if (this.showDetails) {
      this.showDetailsEvent.emit(this.item);
    } else {
      this.showDetailsEvent.emit({ id: undefined });
    }
  }
}
