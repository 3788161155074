import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SharedModule } from '../shared.module';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'app-dynamic-table',
  standalone: true,
  imports: [SharedModule, InfiniteScrollDirective],
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss',
})
export class DynamicTableComponent implements OnChanges {
  @Input() columns: {
    sortable: boolean;
    label: string;
    fieldName: string;
    fieldAlias: string;
    focus?: boolean;
    sortWithKeyword: boolean;
    dateTimeConfig?: {
      format: string;
      timezone: string;
    };
  }[] = [];
  @Input() data: {
    [key: string]: any;
  }[];
  @Input() rowHeaderConfigs: {
    warnRow?: boolean;
    rowToolTip?: string;
    value: any;
    iconConfig?: {
      iconName: string;
      iconTooltip?: string;
      iconType: 'warn' | 'info';
    }[];
  }[];
  @Input() sortedByClick: (field: string) => void;
  @Input() onScrollClick: () => void;
  @Output() showDetailsEvent = new EventEmitter<any>();
  @Output() lastFilteredField = new EventEmitter<string>();
  expandedItem: any | null = null;
  sortBy: string;
  columnControls: {}[];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.columns) {
      this.columnControls = this.columns;
    }

    if (
      changes?.rowHeaderConfigs?.currentValue?.length !==
      changes?.rowHeaderConfigs?.previousValue?.length
    ) {
      this.rowHeaderConfigs = changes.rowHeaderConfigs.currentValue;
    }
  }

  sortedBy(field: string, asc: boolean) {
    this.columnControls = this.columnControls.map((column: any) => {
      column.focus = false;
      if (column.fieldName === field) {
        column.focus = true;
        this.lastFilteredField.emit(column.label);
        this.sortedByClick(column.sortWithKeyword ? `${field}.keyword` : field);
      }
      return column;
    });
  }

  handleShowDetailsEvent($event: any, item: { [key: string]: any }) {
    this.showDetailsEvent.emit(item);
  }
}
