import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import {
  GenericUser,
  ShareUserNotification,
} from '../notification-preferences.model';
import { NotificationPreferencesService } from '../notification-preferences.service';
import { ToastrService } from 'ngx-toastr';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DashboardService } from '../../dashboard.service';
import {
  ActionsBarProperties,
  ButtonStyle,
  setIsProcessingForAllButtons,
} from 'src/app/shared/actions-bar/actions-bar.model';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
})
export class UserPreferencesComponent implements OnInit {
  dropdownList = [];
  selectedItems: UntypedFormControl;
  dropdownSettings = {};
  isLoading: boolean;
  isProcessing: boolean;
  isMobile: boolean;
  private shareUserNotification: ShareUserNotification;
  actionsBarConfig: ActionsBarProperties;

  constructor(
    private notificationPreferencesService: NotificationPreferencesService,
    private authService: AuthService,
    private toastr: ToastrService,
    private breakpointObserver: BreakpointObserver,
    private dashboardService: DashboardService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe((result) => {
      this.dashboardService.changeBreakpointFlag(result.matches);
      this.isMobile = result.matches;
      this.initMultiSelect();
    });
  }

  ngOnInit() {
    this.isLoading = true;

    this.notificationPreferencesService
      .getUserNotificationPreferencesForSelect()
      .then((shareUserNotification: ShareUserNotification) => {
        this.shareUserNotification = shareUserNotification;
        this.initMultiSelect();
      });
    this.initActionsBarConfig();
  }

  initActionsBarConfig() {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnText: 'Submit',
          btnId: 'submit',
          btnClick: () => this.submit(),
          icon: 'arrow_forward',
          buttonStyle: ButtonStyle.PRIMARY,
          isProcessing: this.isProcessing,
        },
      ],
    };
  }

  submit = () => {
    this.isProcessing = true;
    this.actionsBarConfig.buttons = setIsProcessingForAllButtons(
      this.isProcessing,
      this.actionsBarConfig.buttons
    );

    const forwardUsersIds = this.selectedItems.value.map((item) => item.id);

    const notificationPreferences = {
      aduserId: this.authService.user.id,
      forwardUsersIds,
    };

    this.notificationPreferencesService
      .sendUserPartnerNotificationPreferences(notificationPreferences)
      .then(
        (_) => {
          this.toastr.success(
            '',
            'Notification preferences saved with success'
          );
        },
        (error) => {
          this.toastr.error('', 'Error saving the preferences!');
          throw error;
        }
      )
      .then(() => {
        this.isProcessing = false;
        this.actionsBarConfig.buttons = setIsProcessingForAllButtons(
          this.isProcessing,
          this.actionsBarConfig.buttons
        );
      });
  };

  private formatNotificationPref(users: GenericUser[]) {
    return users.map((item) => {
      return { id: item.id, label: item.name };
    });
  }

  initMultiSelect() {
    if (this.shareUserNotification) {
      const formattedSelectedItems = this.formatNotificationPref(
        this.shareUserNotification.sharingWith
      );
      const formattedAllItems = this.formatNotificationPref(
        this.shareUserNotification.allUsers
      );

      this.selectedItems = new UntypedFormControl(formattedSelectedItems);
      this.dropdownList = formattedAllItems;

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'label',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        itemsShowLimit: this.isMobile ? 1 : 3,
        maxHeight: 200,
      };

      this.isLoading = false;
    }
  }
}
