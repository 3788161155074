// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-up-component {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pop-up-component .close-button-wrapper .close {
  height: 15px;
  padding: 10px;
  float: right;
  cursor: pointer;
}
.pop-up-component .body {
  height: calc(85% - 20px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.pop-up-component .body .header {
  width: inherit;
}
.pop-up-component .body .header h3 {
  font-weight: 600;
}
.pop-up-component .body .centered-title {
  display: flex;
  justify-content: center;
}
.pop-up-component .body .actions-bar {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/pop-up/pop-up.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AACI;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AACN;AAGE;EACE,wBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;AADJ;AAGI;EACE,cAAA;AADN;AAEM;EACE,gBAAA;AAAR;AAII;EACE,aAAA;EACA,uBAAA;AAFN;AAKI;EACE,WAAA;AAHN","sourcesContent":[".pop-up-component {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  .close-button-wrapper {\n    .close {\n      height: 15px;\n      padding: 10px;\n      float: right;\n      cursor: pointer;\n    }\n  }\n\n  .body {\n    height: calc(85% - 20px);\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: center;\n\n    .header {\n      width: inherit;\n      h3 {\n        font-weight: 600;\n      }\n    }\n\n    .centered-title {\n      display: flex;\n      justify-content: center;\n    }\n\n    .actions-bar {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
