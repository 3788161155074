import { Component, Input, OnInit } from '@angular/core';
import { ActionsBarProperties, Button } from './actions-bar.model';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-actions-bar',
  templateUrl: './actions-bar.component.html',
  styleUrls: ['./actions-bar.component.scss'],
})
export class ActionsBarComponent implements OnInit {
  @Input() actionsBarProperties: ActionsBarProperties;
  toggle: UntypedFormControl;

  ngOnInit() {
    this.toggle = this.actionsBarProperties?.isToggled;
  }

  onToggleChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.actionsBarProperties.onToggleChange(isChecked);
  }
}
