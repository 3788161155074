import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogType } from './dialog-confirmation.model';
import {
  ActionsBarProperties,
  ButtonStyle,
} from '../actions-bar/actions-bar.model';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss'],
})
export class DialogConfirmationComponent implements OnInit {
  actionsBarConfig: ActionsBarProperties;
  constructor(
    private dialogRef: MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.actionsBarConfig = {
      centralizeComponents: true,
      buttons: [
        {
          btnClick: () => this.onNoClick(false),
          buttonStyle: ButtonStyle.PRIMARY,
          icon: 'close',
          btnText: 'No',
        },
        {
          btnClick: () => this.onNoClick(true),
          buttonStyle: ButtonStyle.SECONDARY,
          icon: 'check',
          btnText: 'Yes',
        },
      ],
    };
  }

  get textStyle() {
    if (this.data.type === DialogType.withWarning) {
      return {
        color: 'red',
        'font-weight': 'bold',
      };
    }
    if (this.data.type === DialogType.textList) {
      return {
        'font-size': '14px',
        padding: '0px 8px',
      };
    }
    return { color: 'black' };
  }

  onNoClick = (option) => {
    this.dialogRef.close(option);
  };
}
