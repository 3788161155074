import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  Subscription,
  Observable,
  of,
  Subject,
  interval,
  merge,
  combineLatest,
} from 'rxjs';
import {
  debounce,
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { unix } from 'moment';
import { decode as htmlDecode } from 'html-entities';

import {
  ServiceType,
  WashBillToTerminal,
  WashRequest,
  WashRequestStatus,
  ProductContent,
  CompartmentServiceType,
  BusinessPartner,
  ReasonOfChangeOption,
  ServicePlan,
  SimplifiedServicePlan,
  ServicePlanProductLine,
} from '../wash-list.model';
import { Warehouse } from '../../../core/model/warehouse.model';
import { WashListService } from '../wash-list.service';
import { IFieldsState, WashCreateFieldsEnum } from './wash-create-fields.enum';
import { ServicePlanRelatedFields } from '../shared/service-plan-related-fields.enum';
import { AuthService } from 'src/app/core/auth/auth.service';
import { compartmentsValidator } from '../shared/compartments-validator.directive';
import { TimeService } from 'src/app/core/time.service';
import { WashItemService } from '../wash-item/wash-item.service';
import { DialogConfirmationService } from 'src/app/shared/dialog-confirmation/dialog-confirmation.service';
import { TankElasticSearch } from 'src/app/core/model/search-definition.model';
import { RequestWarningMessages } from '../wash-list.model';
import { ServiceTypeKeys } from './wash-create.model';
import {
  StandardServicePlanDuration,
  StandardServicePlanNames,
} from '../wash-item/wash-item.model';
import { ServicePlanPopUpOverlayComponent } from '../shared/service-plan-pop-up-overlay/service-plan-pop-up-overlay.component';
import { reasonOfChangeList } from './wash-create.model';
import { ServicePlanPopUpInputModel } from '../shared/service-plan-pop-up-overlay/service-plan-pop-up.model';
import {
  popUpHeaders,
  popUpLabels,
  popUpDisclaimers,
} from '../shared/service-plan-pop-up-overlay/service-plan-pop-up.constants';
import { BusinessPartnerService } from 'src/app/core/services/business-partner.service';
import { DateTimeService } from 'src/app/shared/date-time.service';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import {
  ActionsBarProperties,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';

type ReducedWarehouse = Pick<Warehouse, 'id' | 'searchKey'>;

@Component({
  selector: 'app-wash-create',
  templateUrl: './wash-create.component.html',
  styleUrls: ['./wash-create.component.scss'],
})
export class WashCreateComponent implements OnInit, OnDestroy {
  invalidContainsNitrogenMessage: string =
    'Please inform if this compartment contains nitrogen.';
  compartmentsQty$: Subscription;
  serviceTypeId$: Subscription;
  businessPartnerId$: Subscription;
  showContainersFromAllBusinessPartners$: Subscription;
  isVisible: boolean;
  clickedBtn: string;
  isCreatingContainer = false;
  actionDisclaimer: string;
  canSubmitRequest: boolean;
  isLoadingServicePlan = false;
  containerPlaceholder: string;
  triedSubmit: boolean;
  lastContainedGroupForm: Object;

  // Tank type ahead subscription
  tankInput$ = new Subject<string | null>();
  tankInputSub$: Subscription;

  // Loading BP field
  hasLoadedBPs$: Observable<boolean>;

  // Select options
  businessPartnerOptions$: Observable<BusinessPartner[]>;
  containerOwnerOptions$: Observable<BusinessPartner[]>;
  warehouseOptions$: Observable<ReducedWarehouse[]>;
  serviceTypeOptions$: Observable<ServiceType[]>;
  washBillToTerminalOptions$: Observable<WashBillToTerminal[]>;
  isLoadingTankOptions$: Observable<boolean>;
  productContent$: Observable<ProductContent[]>;
  reasonOfChangeOptions$: Observable<ReasonOfChangeOption[]>;
  compartmentServiceTypeOptions = [
    CompartmentServiceType.SameAsMain,
    CompartmentServiceType.DoNotClean,
    CompartmentServiceType.Clean,
    CompartmentServiceType.CleanAndPrep,
    CompartmentServiceType.Prep,
  ];

  // Form components
  registerForm: UntypedFormGroup;
  customerId: UntypedFormControl;
  phoneNumber: UntypedFormControl;
  container: UntypedFormControl;
  showContainersFromAllBusinessPartners: UntypedFormControl;
  warehouseId: UntypedFormControl;
  arrivalTime: UntypedFormControl;
  arrivalDate: UntypedFormControl;
  needByTime: UntypedFormControl;
  needByDate: UntypedFormControl;
  serviceTypeId: UntypedFormControl;
  exteriorWash: UntypedFormControl;
  foodGrade: UntypedFormControl;
  recommendedWashType: UntypedFormControl;
  wasWashTypeChanged: UntypedFormControl;
  washType: UntypedFormControl;
  reasonOfChange: UntypedFormControl;
  kosher: UntypedFormControl;
  specialInstructions: UntypedFormControl;
  compartmentsQty: UntypedFormControl;
  lastContainedProductComp1Id: UntypedFormControl;
  lastContainedProductComp2Id: UntypedFormControl;
  lastContainedProductComp3Id: UntypedFormControl;
  lastContainedProductComp4Id: UntypedFormControl;
  lastContainedProductComp5Id: UntypedFormControl;
  lastContainedProductComp1Name: UntypedFormControl;
  lastContainedProductComp2Name: UntypedFormControl;
  lastContainedProductComp3Name: UntypedFormControl;
  lastContainedProductComp4Name: UntypedFormControl;
  lastContainedProductComp5Name: UntypedFormControl;
  containsNitrogenComp1: UntypedFormControl;
  containsNitrogenComp2: UntypedFormControl;
  containsNitrogenComp3: UntypedFormControl;
  containsNitrogenComp4: UntypedFormControl;
  containsNitrogenComp5: UntypedFormControl;
  serviceTypeComp1: UntypedFormControl;
  serviceTypeComp2: UntypedFormControl;
  serviceTypeComp3: UntypedFormControl;
  serviceTypeComp4: UntypedFormControl;
  serviceTypeComp5: UntypedFormControl;
  specialInstructionsComp1: UntypedFormControl;
  specialInstructionsComp2: UntypedFormControl;
  specialInstructionsComp3: UntypedFormControl;
  specialInstructionsComp4: UntypedFormControl;
  specialInstructionsComp5: UntypedFormControl;
  lastContained1Id: UntypedFormControl;
  lastContained2Id: UntypedFormControl;
  lastContained3Id: UntypedFormControl;
  lastContained1Name: UntypedFormControl;
  lastContained2Name: UntypedFormControl;
  lastContained3Name: UntypedFormControl;
  nitrogen: UntypedFormControl;
  tractorNumber: UntypedFormControl;
  pumpQty: UntypedFormControl;
  hosesQty: UntypedFormControl;
  fittingsQty: UntypedFormControl;
  ladingBill: UntypedFormControl;
  poNumber: UntypedFormControl;
  poNumberForPrep: UntypedFormControl;
  washBillToTerminalId: UntypedFormControl;
  newTankForm: UntypedFormGroup;

  containerOwnerId: string;
  containerOwnerName: string;
  containerTypeId: string;
  containerTypeName: string;
  containerId: string;
  containerNumber: string;
  invalidFields: Array<string> = [];
  validationMessage: string;
  displayValidationMessage = false;
  showPoNumberForPrep: boolean;
  isProcessing: boolean;
  pumpHoses: Array<number> = new Array(20).fill(1).map((x, i) => i + 1);
  hosesQtyOptions: Array<number> = new Array(10).fill(1).map((x, i) => i + 1);
  poNumberTip: string;
  poNumberPlaceholder: string;
  tankList: Array<any> = [];
  servicePlanObject: any;
  productLines: Array<ServicePlanProductLine>;
  servicePlanObjectOnError: ServicePlan;
  simplifiedServicePlan: SimplifiedServicePlan;
  recommendedServicePlanData: any;
  servicePlanList: Array<any>;
  tankSearchTotalResults: number;
  tankSearchDisplayingResults: number;
  showSearchResultsHelperText: boolean;
  obServiceTypeId: string;
  flareRequired = false;
  customerHasCreditBlock = false;
  lastContainedEmptyNoWash = 'Empty - No Wash';
  arrivalTimestamp: number;
  needByTimestamp: number;
  dateTimeValidators: any[] = [Validators.required];
  invalidPo: Boolean = false;
  compartmentList = [
    { id: 1, label: 1 },
    { id: 2, label: 2 },
    { id: 3, label: 3 },
    { id: 4, label: 4 },
    { id: 5, label: 5 },
  ];
  actionsBarConfig: ActionsBarProperties = { buttons: [] } as any;

  private commonProductToastr: any;

  containerToolTipHintMessage =
    'To search for containers with two digits, add a dash "-" after the second digit.';
  // Attachments
  uploadedFiles: Array<any> = [];

  fieldStatus: IFieldsState = {
    foodGrade: {
      disabled: false,
    },
  };

  get isFoodGrade() {
    return this.foodGrade.value;
  }

  get compartmentsQtyValue() {
    return this.compartmentsQty.value || 1;
  }

  get hasQualaRole() {
    return this.authService.hasQualaWorkerRole();
  }

  get showHeatFields() {
    const serviceType = this.washListService.dataStore.serviceTypes.find(
      (item) => item.id === this.serviceTypeId.value
    );

    if (!serviceType) {
      return false;
    }

    this.obServiceTypeId = serviceType.obId;
    return serviceType.key === ServiceTypeKeys.SteamHeat;
  }

  constructor(
    private authService: AuthService,
    private washListService: WashListService,
    private washItemService: WashItemService,
    private warehouseService: WarehouseService,
    private toastr: ToastrService,
    private timeService: TimeService,
    private dialogConfirmationService: DialogConfirmationService,
    public viewContainerRef: ViewContainerRef,
    public dialog: MatDialog,
    private businessPartnerService: BusinessPartnerService,
    private dateTimeService: DateTimeService
  ) {}

  ngOnInit() {
    this.hasLoadedBPs$ = this.businessPartnerService.hasLoadedBPs;

    this.tankInputSub$ = this.tankInput$
      .pipe(debounceTime(500))
      .subscribe((newTerm) => {
        if (!newTerm) {
          this.tankList = [];
          return;
        }
        this.updateTankList(newTerm);
      });
    this.reasonOfChangeOptions$ =
      this.washListService.reasonOfChangeOptions.pipe(
        map((arr) =>
          arr.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
        )
      );

    this.createFormControls();
    this.createFormGroup();
    this.createSelectOptions();
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isVisible = flag)
    );
    if (!this.customerId.value) {
      this.disableContainerField();
    }

    let initialArrivalDate: string | Date = '';
    let initialArrivalTime: string | number = '';

    [initialArrivalDate, initialArrivalTime] =
      this.dateTimeService.splitDateAndTime(this.timeService.getNowAsUTC());
    this.arrivalDate.setValue(initialArrivalDate);
    this.arrivalTime.setValue(initialArrivalTime);

    this.washType.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((servicePlan) => {
        this.setProductLineQuantityOnControl(
          servicePlan,
          'Hose Clean',
          this.hosesQty
        );
        this.setProductLineQuantityOnControl(
          servicePlan,
          'Pump Clean',
          this.pumpQty
        );
        this.setProductLineQuantityOnControl(
          servicePlan,
          'Fitting Clean',
          this.fittingsQty
        );
      });
    this.updateActionsBarConfigs();
  }

  updateActionsBarConfigs() {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnClick: () => this.cancel(),
          btnId: 'cancel',
          btnText: 'Cancel',
          icon: 'close',
          buttonStyle: ButtonStyle.SECONDARY,
          btnDisabled: this.isCreatingContainer || this.isProcessing,
        },
        {
          btnClick: () => this.submit('Draft'),
          btnId: 'draft',
          btnText: 'Save Draft',
          icon: 'arrow_forward',
          buttonStyle: ButtonStyle.SECONDARY,
          btnDisabled: this.isCreatingContainer || this.isProcessing,
          isProcessing: this.isProcessing && this.clickedBtn === 'Draft',
        },
        {
          btnClick: () => this.submit('Submitted'),
          filterFn: () => this.hasQualaRole,
          btnId: 'submit',
          btnText: 'Submit',
          icon: 'arrow_forward',
          buttonStyle: ButtonStyle.SECONDARY,
          btnDisabled: this.isCreatingContainer || this.isProcessing,
          isProcessing: this.isProcessing && this.clickedBtn === 'Submitted',
        },
        {
          btnClick: () => this.submit('Accepted'),
          btnId: 'in-person-approval',
          btnText: 'In Person Approval',
          icon: 'arrow_forward',
          buttonStyle: ButtonStyle.PRIMARY,
          btnDisabled:
            this.isCreatingContainer ||
            this.isProcessing ||
            this.customerHasCreditBlock,
          isProcessing: this.isProcessing && this.clickedBtn === 'Accepted',
          filterFn: () => this.hasQualaRole,
          disabledTooltip: this.customerHasCreditBlock
            ? 'Account on hold. Requests can not be scheduled. Customer needs to contact their sales representative.'
            : '',
        },
        {
          btnClick: () => this.submit('Submitted'),
          btnId: 'disaptcher-submit',
          btnText: 'Submit',
          icon: 'arrow_forward',
          buttonStyle: ButtonStyle.PRIMARY,
          btnDisabled: this.isCreatingContainer || this.isProcessing,
          isProcessing: this.isProcessing && this.clickedBtn === 'Submitted',
          filterFn: () => !this.hasQualaRole,
        },
      ].filter((button) => (button.filterFn ? button.filterFn() : true)),
    };
  }

  createContainer(isCreatingContainer) {
    this.isCreatingContainer = isCreatingContainer;
    this.updateActionsBarConfigs();
  }

  ngOnDestroy() {
    this.tankInputSub$.unsubscribe();
    this.compartmentsQty$.unsubscribe();
    this.serviceTypeId$.unsubscribe();
    this.showContainersFromAllBusinessPartners$.unsubscribe();
  }

  disableContainerField() {
    this.container.disable();
    this.containerPlaceholder =
      'This field will be disabled until a company has been selected.';
  }

  updateTankList(tankNumber?: string) {
    this.washListService
      .searchTanks(
        new TankElasticSearch(
          0,
          tankNumber,
          this.customerId.value,
          this.showContainersFromAllBusinessPartners.value
        )
      )
      .then((data) => {
        this.tankList = data.list;
        this.tankSearchDisplayingResults = data.list.length;
        this.tankSearchTotalResults = data.total;
      });
  }

  disableFoodGrade() {
    this.foodGrade.disable();
    this.fieldStatus.foodGrade.disabled = true;
  }

  enableFoodGrade() {
    this.foodGrade.enable();
    this.fieldStatus.foodGrade.disabled = false;
  }

  setRequiredFields() {
    if (this.isFoodGrade) {
      this.registerForm.controls.lastContainedProduct1Id.setValidators([
        Validators.required,
      ]);
      this.registerForm.controls.nitrogen.setValidators([Validators.required]);
      this.registerForm.controls.lastContainedProduct1Id.updateValueAndValidity();

      for (let index = 1; index <= this.compartmentsQtyValue; index++) {
        this.registerForm.controls[
          `containsNitrogenComp${index}`
        ].clearValidators();
        this.registerForm.controls[
          `containsNitrogenComp${index}`
        ].updateValueAndValidity();
        this.registerForm.controls[
          `lastContainedProductComp${index}Id`
        ].clearValidators();
        this.registerForm.controls[
          `lastContainedProductComp${index}Id`
        ].updateValueAndValidity();
      }
    } else {
      this.registerForm.controls.lastContainedProduct1Id.clearValidators();
      this.registerForm.controls.lastContainedProduct1Id.updateValueAndValidity();
      this.registerForm.controls.nitrogen.clearValidators();
      this.registerForm.controls.nitrogen.updateValueAndValidity();

      for (let index = 1; index <= this.compartmentsQtyValue; index++) {
        this.registerForm.controls[
          `containsNitrogenComp${index}`
        ].setValidators([Validators.required]);
        this.registerForm.controls[
          `containsNitrogenComp${index}`
        ].updateValueAndValidity();
        this.registerForm.controls[
          `lastContainedProductComp${index}Id`
        ].setValidators([Validators.required]);
        this.registerForm.controls[
          `lastContainedProductComp${index}Id`
        ].updateValueAndValidity();
      }
    }
    this.clearUnnecessaryValidators();
  }

  clearUnnecessaryValidators() {
    const maxCompartmentQuantity = 5;
    for (
      let index = this.compartmentsQtyValue + 1;
      index <= maxCompartmentQuantity;
      index++
    ) {
      this.registerForm.controls[
        `containsNitrogenComp${index}`
      ].clearValidators();
      this.registerForm.controls[
        `containsNitrogenComp${index}`
      ].updateValueAndValidity();
      this.registerForm.controls[
        `lastContainedProductComp${index}Id`
      ].clearValidators();
      this.registerForm.controls[
        `lastContainedProductComp${index}Id`
      ].updateValueAndValidity();
    }
  }

  createFormControls() {
    this.customerId = new UntypedFormControl('', Validators.required);
    this.warehouseId = new UntypedFormControl('', Validators.required);
    const now = unix(this.timeService.getNowAsUTC())
      .second(0)
      .millisecond(0)
      .unix();
    this.arrivalTime = new UntypedFormControl(now, this.dateTimeValidators);
    this.needByTime = new UntypedFormControl('', this.dateTimeValidators);
    this.arrivalDate = new UntypedFormControl('', this.dateTimeValidators);
    this.needByDate = new UntypedFormControl('', this.dateTimeValidators);
    this.phoneNumber = new UntypedFormControl('');
    this.container = new UntypedFormControl(undefined, Validators.required);
    this.showContainersFromAllBusinessPartners = new UntypedFormControl(false);
    this.serviceTypeId = new UntypedFormControl('', Validators.required);
    this.exteriorWash = new UntypedFormControl({
      value: false,
      disabled: false,
    });
    this.foodGrade = new UntypedFormControl({ value: false, disabled: true });
    this.recommendedWashType = new UntypedFormControl({
      value: '',
      disabled: true,
    });
    this.wasWashTypeChanged = new UntypedFormControl(false);
    this.washType = new UntypedFormControl('');
    this.reasonOfChange = new UntypedFormControl('');
    this.kosher = new UntypedFormControl(false);
    this.specialInstructions = new UntypedFormControl('');
    this.compartmentsQty = new UntypedFormControl({ value: 1, disabled: true });
    this.lastContainedProductComp1Id = new UntypedFormControl('');
    this.lastContainedProductComp2Id = new UntypedFormControl('');
    this.lastContainedProductComp3Id = new UntypedFormControl('');
    this.lastContainedProductComp4Id = new UntypedFormControl('');
    this.lastContainedProductComp5Id = new UntypedFormControl('');
    this.lastContainedProductComp1Name = new UntypedFormControl('');
    this.lastContainedProductComp2Name = new UntypedFormControl('');
    this.lastContainedProductComp3Name = new UntypedFormControl('');
    this.lastContainedProductComp4Name = new UntypedFormControl('');
    this.lastContainedProductComp5Name = new UntypedFormControl('');
    this.containsNitrogenComp1 = new UntypedFormControl(
      undefined,
      Validators.required
    );
    this.containsNitrogenComp3 = new UntypedFormControl(
      undefined,
      Validators.required
    );
    this.containsNitrogenComp2 = new UntypedFormControl(
      undefined,
      Validators.required
    );
    this.containsNitrogenComp4 = new UntypedFormControl(
      undefined,
      Validators.required
    );
    this.containsNitrogenComp5 = new UntypedFormControl(
      undefined,
      Validators.required
    );
    this.serviceTypeComp1 = new UntypedFormControl(
      CompartmentServiceType.SameAsMain
    );
    this.serviceTypeComp2 = new UntypedFormControl(
      CompartmentServiceType.SameAsMain
    );
    this.serviceTypeComp3 = new UntypedFormControl(
      CompartmentServiceType.SameAsMain
    );
    this.serviceTypeComp4 = new UntypedFormControl(
      CompartmentServiceType.SameAsMain
    );
    this.serviceTypeComp5 = new UntypedFormControl(
      CompartmentServiceType.SameAsMain
    );
    this.specialInstructionsComp1 = new UntypedFormControl('');
    this.specialInstructionsComp2 = new UntypedFormControl('');
    this.specialInstructionsComp3 = new UntypedFormControl('');
    this.specialInstructionsComp4 = new UntypedFormControl('');
    this.specialInstructionsComp5 = new UntypedFormControl('');
    this.lastContained1Id = new UntypedFormControl('');
    this.lastContained2Id = new UntypedFormControl('');
    this.lastContained3Id = new UntypedFormControl('');
    this.lastContained1Name = new UntypedFormControl('');
    this.lastContained2Name = new UntypedFormControl('');
    this.lastContained3Name = new UntypedFormControl('');
    this.nitrogen = new UntypedFormControl(undefined, Validators.required);
    this.tractorNumber = new UntypedFormControl('');
    this.pumpQty = new UntypedFormControl(0);
    this.hosesQty = new UntypedFormControl(0);
    this.fittingsQty = new UntypedFormControl(0);
    this.ladingBill = new UntypedFormControl('');
    this.poNumber = new UntypedFormControl('', Validators.required);
    this.poNumberForPrep = new UntypedFormControl('');
    this.washBillToTerminalId = new UntypedFormControl('', Validators.required);

    // Subscribe to changes
    merge(
      this.customerId.valueChanges,
      this.serviceTypeId.valueChanges,
      this.container.valueChanges,
      this.exteriorWash.valueChanges,
      this.foodGrade.valueChanges,
      this.lastContained1Id.valueChanges,
      this.lastContained2Id.valueChanges,
      this.lastContained3Id.valueChanges,
      this.lastContainedProductComp1Id.valueChanges,
      this.lastContainedProductComp2Id.valueChanges,
      this.lastContainedProductComp3Id.valueChanges,
      this.lastContainedProductComp4Id.valueChanges,
      this.lastContainedProductComp5Id.valueChanges
    )
      .pipe(debounce(() => interval(500)))
      .subscribe((_) => {
        this.getServicePlanData();
      });

    if (this.authService.hasDispatcherRole()) {
      this.checkCommonProducts();
    }

    this.businessPartnerId$ = this.customerId.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.container.enable();
          this.containerPlaceholder = '';

          if (!this.showContainersFromAllBusinessPartners.value) {
            this.registerForm.controls.container.setValue(null);
            this.setContainerControlValidity();
          }

          this.checkIfCustomerHasCreditBlock(value);

          const defaultExteriorWashValue =
            this.washListService.hasExteriorWashByDefault(value);
          this.exteriorWash.setValue(defaultExteriorWashValue);

          this.washListService.loadWashBillToTerminal(value);
          // After story QUAL-1921 adjust this settings
          const businessPartner =
            this.businessPartnerService.dataStore.businessPartners.find(
              (item) => item.id === value
            );
          this.poNumberPlaceholder =
            businessPartner && businessPartner.contmngPOFormathelp;
          this.poNumberTip =
            this.poNumberPlaceholder &&
            `${this.poNumberPlaceholder}. If the customer will provide the PO number later, enter the word 'pending'.`;
        } else {
          this.registerForm.controls.container.setValue(null);
          this.setContainerControlValidity();
          this.container.disable();
          this.containerPlaceholder =
            'This field will be disabled until a company has been selected.';
        }
      }
    );

    this.compartmentsQty$ = this.compartmentsQty.valueChanges.subscribe((_) => {
      this.cleanHiddenFields();
    });

    this.serviceTypeId$ = this.serviceTypeId.valueChanges.subscribe(
      (serviceTypeOnTraxId) => {
        this.serviceTypeHandler(serviceTypeOnTraxId);
      }
    );

    this.showContainersFromAllBusinessPartners$ =
      this.showContainersFromAllBusinessPartners.valueChanges.subscribe(
        (value) => {
          if (!value) {
            this.tankSearchDisplayingResults = null;
            this.registerForm.controls.container.setValue(null);
            this.setContainerControlValidity();
          } else {
            if (this.containerNumber && this.customerId.value) {
              this.updateTankList(this.containerNumber);
            }
          }
        }
      );

    this.foodGrade.valueChanges.subscribe((value) => {
      this.foodGradeChangesHandler(value);
      this.setRequiredFields();
    });

    this.container.valueChanges.subscribe((selectedTank) => {
      if (selectedTank) {
        this.containerOwnerId = selectedTank.businessPartnerId;
        this.containerOwnerName = selectedTank.businessPartnerName;
        this.containerTypeId = selectedTank.containerTypeId;
        this.containerTypeName = selectedTank.containerTypeName;
        this.containerId = selectedTank.id;
        this.containerNumber = selectedTank.searchKey;
        this.compartmentsQty.setValue(+selectedTank.compartments);

        const canBeFoodGrade =
          this.washListService.dataStore.containerTypes.some(
            (item) =>
              item.name === selectedTank.containerTypeName &&
              item.canBeFoodGrade
          );

        const isFoodGradeOnly = this.washListService.isFoodGradeOnly(
          selectedTank.containerTypeName,
          canBeFoodGrade
        );

        if (isFoodGradeOnly) {
          this.foodGrade.setValue(true);
          this.compartmentsQty.setValue(1);
          this.disableFoodGrade();
        } else if (canBeFoodGrade) {
          this.enableFoodGrade();
        } else {
          this.foodGrade.setValue(false);
          this.disableFoodGrade();
        }
        this.handleExteriorWashToggle(selectedTank);
      } else {
        this.containerOwnerId = null;
        this.containerOwnerName = null;
        this.containerTypeId = null;
        this.containerTypeName = null;
        this.containerId = null;
        this.containerNumber = null;
        this.compartmentsQty.setValue(null);
        this.foodGrade.setValue(false);
        this.disableFoodGrade();
        this.exteriorWash.enable();
      }
    });

    [
      { control: this.serviceTypeComp1, controlName: 'serviceTypeComp1' },
      { control: this.serviceTypeComp2, controlName: 'serviceTypeComp2' },
      { control: this.serviceTypeComp3, controlName: 'serviceTypeComp3' },
      { control: this.serviceTypeComp4, controlName: 'serviceTypeComp4' },
      { control: this.serviceTypeComp5, controlName: 'serviceTypeComp5' },
    ].forEach(({ control, controlName }) =>
      control.valueChanges.subscribe((value) =>
        this.handlerServiceTypeCompChanges(controlName, value)
      )
    );

    combineLatest([
      this.container.valueChanges,
      this.customerId.valueChanges,
    ]).subscribe(([selectedTank, customerId]) => {
      if (selectedTank) {
        const lookupTime = 90;
        const queryStartRange = this.timeService.getDateAsUTC(lookupTime);
        const customer =
          this.businessPartnerService.dataStore.businessPartners.find(
            (item) => item.id === customerId
          );
        this.washListService
          .existWashRequest(
            selectedTank.searchKey,
            customer.name,
            queryStartRange
          )
          .then((result: any) => {
            if (result && result.length > 0) {
              const existingItem = result[0];
              const customerName = customer.name;
              const containerType = this.containerTypeName;
              const terminal = existingItem.terminal;
              const warningMessage = `<b>There is a Request in progress for this Tank!</b><br>
              <b>Customer Name:</b> ${customerName}.<br>
              <b>Container Type:</b> ${containerType}.<br>
              <b>Terminal:</b> ${terminal}.`;
              this.toastr.warning(warningMessage, '', { enableHtml: true });
            }
          });
      }
    });
  }

  checkCommonProducts() {
    this.warehouseId.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      if (value) {
        this.checkAllContentIds();
      }
    });

    const contentIdObservables = [
      this.lastContained1Id.valueChanges,
      this.lastContained2Id.valueChanges,
      this.lastContained3Id.valueChanges,
      this.lastContainedProductComp1Id.valueChanges,
      this.lastContainedProductComp2Id.valueChanges,
      this.lastContainedProductComp3Id.valueChanges,
      this.lastContainedProductComp4Id.valueChanges,
      this.lastContainedProductComp5Id.valueChanges,
    ];

    merge(...contentIdObservables)
      .pipe(debounceTime(300))
      .subscribe((value) => {
        if (value && this.warehouseId.value) {
          this.checkAllContentIds();
        }
      });
  }

  private checkAllContentIds() {
    const allContentIds = [
      this.lastContainedProductComp1Id.value,
      this.lastContainedProductComp2Id.value,
      this.lastContainedProductComp3Id.value,
      this.lastContainedProductComp4Id.value,
      this.lastContainedProductComp5Id.value,
    ];

    const uniqueNonNullValues =
      this.washListService.uniqueNonNullProducts(allContentIds);

    if (uniqueNonNullValues.length > 0) {
      this.washListService
        .getCommonProduct(uniqueNonNullValues, this.warehouseId.value)
        .then((result) => {
          this.handleCommonProductResult(result);
        });
    }
  }

  private handleCommonProductResult(result: any) {
    const products: string[] = [];
    let matchNotFound = false;
    this.toastr.clear(this.commonProductToastr?.id);
    result.forEach((item) => {
      if (!item.findTerminalResult && item.contentName) {
        products.push(item.contentName);
        matchNotFound = true;
      }
    });
    if (matchNotFound) {
      const productList = this.washListService.formatProductList(products);
      this.commonProductToastr = this.toastr.warning(
        `The selected last contained product has not been serviced at this terminal in the last 12 months.
        Please contact the terminal office to confirm your appointment and any possible service restrictions.
        Product Name: ${productList} <br><b>Click here to close this message.</b>`,
        'Alert: Possible Delay or Cancellation',
        {
          enableHtml: true,
          disableTimeOut: true,
          tapToDismiss: true,
        }
      );
    }
  }

  private foodGradeChangesHandler(isFoodGrade: boolean) {
    if (isFoodGrade) {
      this.pumpQty.setValue(1);
      this.hosesQty.setValue(2);
      this.compartmentsQty.setValue(1); // Force value to 1

      this.washListService.toggleServiceTypeOptions(true);
    } else {
      const qty = this.container.value && +this.container.value.compartments;
      this.compartmentsQty.setValue(qty || 1); // Force value to 1
      this.washListService.toggleServiceTypeOptions(true);
    }
  }

  private serviceTypeHandler(serviceTypeOnTraxId: string) {
    const serviceType = this.washListService.dataStore.serviceTypes.find(
      (item) => item.id === serviceTypeOnTraxId
    );
    this.obServiceTypeId = serviceType.obId;
    if (serviceType.key === ServiceTypeKeys.CleanAndPrep) {
      this.showPoNumberForPrep = true;
      this.registerForm.removeControl('steamHeatData');
    } else if (serviceType.key === ServiceTypeKeys.SteamHeat) {
      this.showPoNumberForPrep = false;
      this.poNumberForPrep.setValue('');
    } else if (serviceType.key === ServiceTypeKeys.ExteriorWashOnly) {
      this.handleExteriorWashOnlyScenario();
    } else if (serviceType.key === ServiceTypeKeys.Prep) {
      this.handlePrepScenario();
    } else {
      this.showPoNumberForPrep = false;
      this.poNumberForPrep.setValue('');
      this.registerForm.removeControl('steamHeatData');
    }
  }

  handlePrepScenario() {
    const lastContainedProductComp1 =
      this.washListService.dataStore.productContents.find(
        (item) => item.name === this.lastContainedEmptyNoWash
      );
    this.lastContainedProductComp1Id.setValue(lastContainedProductComp1.id);
  }

  handleExteriorWashOnlyScenario() {
    this.exteriorWash.setValue(true);
    this.exteriorWash.disable();
    const lastContainedProductComp1 =
      this.washListService.dataStore.productContents.find(
        (item) => item.name === this.lastContainedEmptyNoWash
      );
    this.lastContainedProductComp1Id.setValue(lastContainedProductComp1.id);
  }

  private async handlerServiceTypeCompChanges(
    controlName: string,
    value: string
  ) {
    const lastContainedControl = this.registerForm.get(
      `${controlName.replace('serviceType', 'lastContainedProduct')}Id`
    );

    if (value === 'Do not clean' || this.isLoadingServicePlan) {
      const product = this.washListService.getProductContent('Empty');
      lastContainedControl.patchValue(product.id);
      lastContainedControl.disable();
    } else {
      lastContainedControl.enable();
    }
  }

  createFormGroup() {
    this.registerForm = new UntypedFormGroup({
      customerId: this.customerId,
      warehouseId: this.warehouseId,
      phoneNumber: this.phoneNumber,
      container: this.container,
      showContainersFromAllBusinessPartners:
        this.showContainersFromAllBusinessPartners,
      arrivalTime: this.arrivalTime,
      arrivalDate: this.arrivalDate,
      needByDate: this.needByDate,
      needByTime: this.needByTime,
      serviceTypeId: this.serviceTypeId,
      exteriorWash: this.exteriorWash,
      foodGrade: this.foodGrade,
      recommendedWashType: this.recommendedWashType,
      wasWashTypeChanged: this.wasWashTypeChanged,
      washType: this.washType,
      reasonOfChange: this.reasonOfChange,
      kosher: this.kosher,
      specialInstructions: this.specialInstructions,
      compartmentsQty: this.compartmentsQty,
      lastContainedProductComp1Id: this.lastContainedProductComp1Id,
      lastContainedProductComp2Id: this.lastContainedProductComp2Id,
      lastContainedProductComp3Id: this.lastContainedProductComp3Id,
      lastContainedProductComp4Id: this.lastContainedProductComp4Id,
      lastContainedProductComp5Id: this.lastContainedProductComp5Id,
      lastContainedProductComp1Name: this.lastContainedProductComp1Name,
      lastContainedProductComp2Name: this.lastContainedProductComp2Name,
      lastContainedProductComp3Name: this.lastContainedProductComp3Name,
      lastContainedProductComp4Name: this.lastContainedProductComp4Name,
      lastContainedProductComp5Name: this.lastContainedProductComp5Name,
      containsNitrogenComp1: this.containsNitrogenComp1,
      containsNitrogenComp2: this.containsNitrogenComp2,
      containsNitrogenComp3: this.containsNitrogenComp3,
      containsNitrogenComp4: this.containsNitrogenComp4,
      containsNitrogenComp5: this.containsNitrogenComp5,
      serviceTypeComp1: this.serviceTypeComp1,
      serviceTypeComp2: this.serviceTypeComp2,
      serviceTypeComp3: this.serviceTypeComp3,
      serviceTypeComp4: this.serviceTypeComp4,
      serviceTypeComp5: this.serviceTypeComp5,
      specialInstructionsComp1: this.specialInstructionsComp1,
      specialInstructionsComp2: this.specialInstructionsComp2,
      specialInstructionsComp3: this.specialInstructionsComp3,
      specialInstructionsComp4: this.specialInstructionsComp4,
      specialInstructionsComp5: this.specialInstructionsComp5,
      lastContainedProduct1Id: this.lastContained1Id,
      lastContainedProduct2Id: this.lastContained2Id,
      lastContainedProduct3Id: this.lastContained3Id,
      lastContainedProduct1Name: this.lastContained1Name,
      lastContainedProduct2Name: this.lastContained2Name,
      lastContainedProduct3Name: this.lastContained3Name,
      nitrogen: this.nitrogen,
      tractorNumber: this.tractorNumber,
      pumpQty: this.pumpQty,
      hosesQty: this.hosesQty,
      fittingsQty: this.fittingsQty,
      ladingBill: this.ladingBill,
      poNumber: this.poNumber,
      poNumberForPrep: this.poNumberForPrep,
      washBillToTerminalId: this.washBillToTerminalId,
    });

    this.lastContainedGroupForm = {
      compartment1: {
        control: this.lastContainedProductComp1Id,
        containsNitrogen: { control: this.containsNitrogenComp1 },
        specialTreatment: { control: this.specialInstructionsComp1 },
        serviceType: { control: this.serviceTypeComp1 },
      },
      compartment2: {
        control: this.lastContainedProductComp2Id,
        containsNitrogen: { control: this.containsNitrogenComp2 },
        specialTreatment: { control: this.specialInstructionsComp2 },
        serviceType: { control: this.serviceTypeComp2 },
      },
      compartment3: {
        control: this.lastContainedProductComp3Id,
        containsNitrogen: { control: this.containsNitrogenComp3 },
        specialTreatment: { control: this.specialInstructionsComp3 },
        serviceType: { control: this.serviceTypeComp3 },
      },
      compartment4: {
        control: this.lastContainedProductComp4Id,
        containsNitrogen: { control: this.containsNitrogenComp4 },
        specialTreatment: { control: this.specialInstructionsComp4 },
        serviceType: { control: this.serviceTypeComp4 },
      },
      compartment5: {
        control: this.lastContainedProductComp5Id,
        containsNitrogen: { control: this.containsNitrogenComp5 },
        specialTreatment: { control: this.specialInstructionsComp5 },
        serviceType: { control: this.serviceTypeComp5 },
      },
      foodGrade1: { control: this.lastContained1Id },
      foodGrade2: { control: this.lastContained2Id },
      foodGrade3: { control: this.lastContained3Id },
      foodGrade: { containsNitrogen: { control: this.nitrogen } },
    };
  }

  onContainerCreated(container: any) {
    this.isCreatingContainer = false;
    if (!container) {
      return;
    }
    this.container.setValue(container);
    this.updateActionsBarConfigs();
  }

  getValidationMessage(invalidFields) {
    if (invalidFields.length === 1) {
      return `Please enter a ${invalidFields[0]}`;
    }
    const firsts = invalidFields.slice(0, invalidFields.length - 1);
    const last = invalidFields[invalidFields.length - 1];
    return `Please enter a ${firsts.join(', ')} and ${last}`;
  }

  changeFieldsStatus(fieldsToDisable: any, status: string) {
    Object.keys(this.registerForm.controls).forEach((key) => {
      if (fieldsToDisable[key]) {
        if (status === 'enable') {
          if (this.fieldStatus[key] && this.fieldStatus[key].disabled) {
            this.registerForm.get(key as string).disable({
              emitEvent: false,
            });
          } else {
            this.registerForm.get(key as string).enable({
              emitEvent: false,
            });
          }
        } else if (status === 'disable') {
          this.registerForm.get(key as string).disable({
            emitEvent: false,
          });
        }
      }
    });
  }

  setContainerControlValidity() {
    if (
      !this.registerForm.controls.container.value ||
      !this.registerForm.controls.container.value.businessPartnerId ||
      !this.registerForm.controls.container.value.businessPartnerId.trim() ||
      !this.registerForm.controls.container.value.businessPartnerName ||
      !this.registerForm.controls.container.value.businessPartnerName.trim() ||
      !this.registerForm.controls.container.value.containerTypeId ||
      !this.registerForm.controls.container.value.containerTypeId.trim() ||
      !this.registerForm.controls.container.value.containerTypeName ||
      !this.registerForm.controls.container.value.containerTypeName.trim() ||
      !this.registerForm.controls.container.value.id ||
      !this.registerForm.controls.container.value.id.trim() ||
      !this.registerForm.controls.container.value.searchKey ||
      !this.registerForm.controls.container.value.searchKey.trim()
    ) {
      this.container.setValue(null);
      this.container.reset();
      this.tankList = [];
      /* tslint:disable:no-string-literal */
      const containerInputElement = document.querySelector('#container input');
      if (containerInputElement) {
        containerInputElement['value'] = null;
      }
    }
  }

  getRegisterFormValidationErrors() {
    const invalidFields = [];

    this.setContainerControlValidity();
    invalidFields.push(...this.getFormValidationErrors(this.registerForm));

    if (this.needByTimestamp && this.arrivalTimestamp >= this.needByTimestamp) {
      invalidFields.push(
        'Need By Time should be greater than the Arrival Time.'
      );
    }

    // Apply cross-fields validation on compartments
    const compartmentsValidationError: ValidationErrors =
      compartmentsValidator(this.registerForm) || {};
    Object.values(compartmentsValidationError).forEach((value) => {
      const invalidField = WashCreateFieldsEnum[value];
      if (!invalidFields.includes(invalidField)) {
        invalidFields.push(invalidField);
      }
    });

    return invalidFields;
  }

  getFormValidationErrors(formGroup: UntypedFormGroup): Array<any> {
    const invalidFields = [];
    Object.keys(formGroup.controls).forEach((key) => {
      if (formGroup.get(key) instanceof UntypedFormGroup) {
        const validationErrors = this.getFormValidationErrors(
          formGroup.get(key) as UntypedFormGroup
        );
        invalidFields.push(...validationErrors);
        return;
      }

      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          const invalidField = WashCreateFieldsEnum[key];
          if (!invalidFields.includes(invalidField)) {
            invalidFields.push(invalidField);
          }
        });
      }
    });

    return invalidFields;
  }

  createSelectOptions() {
    if (this.authService.hasDispatcherRole()) {
      const { user } = this.authService;
      this.businessPartnerOptions$ = of([
        { id: user.businessPartnerId, name: user.businessPartnerName },
      ]);
      this.customerId.setValue(user.businessPartnerId);
      this.customerId.markAsTouched();
      this.customerId.disable();
      this.warehouseOptions$ = this.warehouseService.warehouses$;
    } else {
      this.businessPartnerOptions$ =
        this.businessPartnerService.businessPartners;
      this.warehouseOptions$ = of([
        {
          id: this.authService.user.currentTerminal.id,
          searchKey: this.authService.user.currentTerminal.key,
        },
      ]);
      this.warehouseId.setValue(this.authService.user.currentTerminal.id);
      this.warehouseId.markAsTouched();
      this.warehouseId.disable();
    }
    this.washBillToTerminalOptions$ =
      this.washListService.washBillToTerminals.pipe(
        map((data) =>
          data.filter(
            (item) => item.businessPartnerId === this.customerId.value
          )
        )
      );
    this.serviceTypeOptions$ = this.washListService.serviceTypes.pipe(
      tap((array) => {
        array.sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
      })
    );
    this.productContent$ = this.washListService.productContents;
    this.containerOwnerOptions$ = this.businessPartnerService.businessPartners;
  }

  checkIfCustomerHasCreditBlock(customerId: string) {
    const businessPartners =
      this.businessPartnerService.dataStore.businessPartners.find(
        (item) => item.id === customerId
      );
    this.customerHasCreditBlock =
      businessPartners && businessPartners.customerBlocking;
  }

  cleanHiddenFields() {
    // LastContainedProductComps
    if (this.foodGrade.value) {
      let nitrogen;
      this.registerForm.controls[`nitrogen`].value;
      nitrogen = undefined;
      this.registerForm.controls[`nitrogen`].setValue(nitrogen, {
        emitEvent: false,
      });
    } else {
      this.cleanNoFoodCompartments(this.compartmentsQtyValue);
      this.lastContained1Id.setValue(undefined, { emitEvent: false });
      this.lastContained2Id.setValue(undefined, { emitEvent: false });
      this.lastContained3Id.setValue(undefined, { emitEvent: false });
    }
  }

  cleanNoFoodCompartments(visibleCompsQty: number) {
    for (let index = 1; index <= 5; index++) {
      this.registerForm.controls[`lastContainedProductComp${index}Id`].setValue(
        undefined,
        { emitEvent: false }
      );
      this.registerForm.controls[`containsNitrogenComp${index}`].setValue(
        undefined,
        { emitEvent: false }
      );
      this.registerForm.controls[`serviceTypeComp${index}`].setValue(
        CompartmentServiceType.SameAsMain,
        { emitEvent: false }
      );
      this.registerForm.controls[`specialInstructionsComp${index}`].setValue(
        undefined,
        { emitEvent: false }
      );
    }
  }

  setProductContentToWashRequest(washRequest: WashRequest) {
    const lastContainedProductComp1 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp1Id
      );
    washRequest.lastContainedProductComp1Name =
      lastContainedProductComp1 && lastContainedProductComp1.name;
    const lastContainedProductComp2 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp2Id
      );
    washRequest.lastContainedProductComp2Name =
      lastContainedProductComp2 && lastContainedProductComp2.name;
    const lastContainedProductComp3 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp3Id
      );
    washRequest.lastContainedProductComp3Name =
      lastContainedProductComp3 && lastContainedProductComp3.name;
    const lastContainedProductComp4 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp4Id
      );
    washRequest.lastContainedProductComp4Name =
      lastContainedProductComp4 && lastContainedProductComp4.name;
    const lastContainedProductComp5 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProductComp5Id
      );
    washRequest.lastContainedProductComp5Name =
      lastContainedProductComp5 && lastContainedProductComp5.name;

    const lastContainedProduct1 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProduct1Id
      );
    washRequest.lastContainedProduct1Name =
      lastContainedProduct1 && lastContainedProduct1.name;
    const lastContainedProduct2 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProduct2Id
      );
    washRequest.lastContainedProduct2Name =
      lastContainedProduct2 && lastContainedProduct2.name;
    const lastContainedProduct3 =
      this.washListService.dataStore.productContents.find(
        (item) => item.id === washRequest.lastContainedProduct3Id
      );
    washRequest.lastContainedProduct3Name =
      lastContainedProduct3 && lastContainedProduct3.name;

    return washRequest;
  }

  setIsProcessing(isProcessing: boolean, clickedBtn?: string) {
    this.isProcessing = isProcessing;
    if (!isProcessing) {
      this.clickedBtn = '';
    } else {
      this.clickedBtn = clickedBtn;
    }
    this.updateActionsBarConfigs();
  }

  lookingForServicePlan(isSearching: boolean) {
    this.isProcessing = isSearching;
    this.isLoadingServicePlan = isSearching;
  }

  // Actions
  submit = async (status: string) => {
    this.invalidPo = false;

    this.setIsProcessing(true, status);
    this.displayValidationMessage = false;
    this.triedSubmit = true;

    // Convert Date and Time to timestamp
    const arrivalDate = this.arrivalDate.value;
    const arrivalTime = this.arrivalTime.value;
    const needByDate = this.needByDate.value;
    const needByTime = this.needByTime.value;
    this.arrivalTimestamp =
      this.dateTimeService.getTimestampInSecondsFromDateAndTime(
        arrivalDate,
        arrivalTime
      );
    this.needByTimestamp =
      this.dateTimeService.getTimestampInSecondsFromDateAndTime(
        needByDate,
        needByTime
      );

    const invalidFields = this.getRegisterFormValidationErrors();
    if (this.wasWashTypeChanged.value && !this.reasonOfChange.value) {
      invalidFields.push('Reason of Change');
    }

    if (invalidFields.length > 0) {
      this.validationMessage = this.getValidationMessage(invalidFields);
      this.displayValidationMessage = true;
      this.setIsProcessing(false);
      return;
    }

    let newWashRequest: WashRequest = new WashRequest(
      this.registerForm.getRawValue()
    );

    newWashRequest.arrivalTime = this.arrivalTimestamp;
    newWashRequest.needByTime = this.needByTimestamp;

    delete newWashRequest.arrivalDate;
    delete newWashRequest.needbyDate;

    // Customer
    const customer =
      this.businessPartnerService.dataStore.businessPartners.find(
        (item) => item.id === this.customerId.value
      );
    newWashRequest.customerId = this.customerId.value;
    newWashRequest.customer = customer && customer.name;
    // Container Owner
    newWashRequest.containerOwner = this.containerOwnerName;
    newWashRequest.containerOwnerId = this.containerOwnerId;
    // Container Number
    newWashRequest.containerId = this.containerId;
    newWashRequest.containerNumber = this.containerNumber;
    // Container Type
    newWashRequest.containerType = this.containerTypeName;
    newWashRequest.containerTypeId = this.containerTypeId;
    // Customer Comments
    const customerComments = customer && customer.contmngCustomernotesParsed;

    // Set value (names) extracted from id
    // Service Type
    const serviceType = this.washListService.dataStore.serviceTypes.find(
      (item) => item.id === newWashRequest.serviceTypeId
    );
    newWashRequest.serviceType = serviceType && serviceType.displayName;
    newWashRequest.serviceTypeOntraxId = serviceType && serviceType.id;
    newWashRequest.serviceTypeId = serviceType && serviceType.obId;
    // Wash Bill To Terminal
    const washBillToTerminal =
      this.washListService.dataStore.billToTerminals.find(
        (item) => item.id === newWashRequest.washBillToTerminalId
      );
    newWashRequest.washBillToTerminal =
      washBillToTerminal && washBillToTerminal.name;

    newWashRequest = this.setProductContentToWashRequest(newWashRequest);

    // Set attachments
    newWashRequest.files = this.uploadedFiles;

    // Warehouse
    newWashRequest.terminal = this.washListService.getTerminalSearchKey(
      this.warehouseId.value
    );
    newWashRequest.terminalId = this.warehouseId.value;

    // Clear steam heat data if not heat service
    const isHeatRequest = serviceType.key === ServiceTypeKeys.SteamHeat;
    if (!isHeatRequest) {
      delete newWashRequest.steam;
      delete newWashRequest.steamCost;
      delete newWashRequest.steamHeatData;
    }

    // Set fixed values
    newWashRequest.status = WashRequestStatus[status];

    newWashRequest = this.trimRequestAttributes(newWashRequest);

    const poValidationResult = this.washItemService.applyPOValidation({
      businessPartner: customer,
      poNumber: newWashRequest.poNumber,
      poNumberForPrep: newWashRequest.poNumberForPrep,
    });

    // Set service plan for Quala Employee
    if (this.hasQualaRole) {
      if (this.servicePlanObject) {
        newWashRequest =
          this.setServicePlanDataToWashRequestQuala(newWashRequest);
      } else {
        newWashRequest =
          this.setServicePlanDataToWashRequestWhenError(newWashRequest);
      }
    }

    // Set service plan for Dispatcher
    if (!this.hasQualaRole) {
      if (this.servicePlanObject) {
        newWashRequest =
          this.setServicePlanDataToWashRequestDispatcher(newWashRequest);
      } else {
        newWashRequest =
          this.setServicePlanDataToWashRequestWhenError(newWashRequest);
      }
    }

    // If valid PO, show service plan confirmation pop up to create request
    if (poValidationResult.isValid) {
      if (this.hasQualaRole) {
        const averageAutomatedConversionTime =
          (await this.washListService.getAverageAutomatedConversionTime()) ||
          '';

        if (status === 'Accepted') {
          this.displayServicePlanOverlay(
            'accepted',
            this.actionDisclaimer,
            newWashRequest,
            this.canSubmitRequest,
            customerComments,
            averageAutomatedConversionTime
          );
          return;
        } else if (status === 'Submitted') {
          this.displayServicePlanOverlay(
            'submitted',
            this.actionDisclaimer,
            newWashRequest,
            this.canSubmitRequest,
            customerComments,
            averageAutomatedConversionTime
          );
          return;
        }
      }
      this.createWashRequest(newWashRequest);
      return;
    }

    // Quala employees are not allowed to submit or accept invalid PO numbers
    // If invalid, warn or block the user before submitting
    const shouldBlockIfInvalidPO =
      this.hasQualaRole &&
      [WashRequestStatus.Accepted, WashRequestStatus.Submitted].includes(
        newWashRequest.status
      );
    if (shouldBlockIfInvalidPO) {
      this.invalidPo = true;
      this.toastr.error(poValidationResult.message, 'Invalid PO Number');
      this.setIsProcessing(false);
      return;
    }

    // Otherwise, just show a confirmation dialog
    const result = await this.dialogConfirmationService.openDialog({
      text: `${poValidationResult.message}. Continue?`,
      icon: 'check',
      action: 'Yes',
      title: 'Invalid PO Number',
    });
    if (!result) {
      // User decided to fix PO number
      this.setIsProcessing(false);
      return;
    }

    // User ignored invalid PO and created wash request
    if (this.hasQualaRole) {
      if (status === 'Accepted') {
        this.displayServicePlanOverlay(
          'accepted',
          this.actionDisclaimer,
          newWashRequest,
          this.canSubmitRequest,
          customerComments
        );
        return;
      } else if (status === 'Submitted') {
        this.displayServicePlanOverlay(
          'submitted',
          this.actionDisclaimer,
          newWashRequest,
          this.canSubmitRequest,
          customerComments
        );
        return;
      }
    }
    this.createWashRequest(newWashRequest);
    return;
  };

  private createWashRequest(newWashRequest: WashRequest) {
    this.washListService.create(newWashRequest).then(
      (response) => {
        if (response.warning) {
          if (
            response.warning.toUpperCase() ===
            RequestWarningMessages.STEAM_SDS_FILE_MISSING
          ) {
            this.toastr.warning(
              'Request has been received but it will not be processed until the SDS is received.',
              'Missing SDS file'
            );
          } else if (
            response.warning.toUpperCase() ===
            RequestWarningMessages.CREDIT_HOLD
          ) {
            if (this.authService.hasQualaWorkerRole) {
              this.toastr.warning(
                'Requests will not be scheduled. Customer needs to contact the sales representative.',
                'Account on hold.'
              );
            } else {
              this.toastr.warning(
                'Requests will not be scheduled. Please contact your sales representative.',
                'Account on hold.'
              );
            }
          } else if (
            response.warning.toUpperCase() ===
            RequestWarningMessages.OUTSIDE_WORKING_HOURS
          ) {
            this.toastr.warning(
              'Terminal is closed right now. Quala will evaluate it as soon as the location starts their operation.',
              'Outside working hours'
            );
          }
        } else {
          this.toastr.success('', 'Request created!');
        }

        this.washListService.resetTankDataStore();
        const option = this.washListService.getOption();

        setTimeout(() => {
          this.cancel();
          this.washListService.loadByOption(option).catch((error) => {
            throw error;
          });
          this.setIsProcessing(false);
        }, 1500);
      },
      (error) => {
        this.validationMessage = 'Error trying to create Wash Request';
        this.displayValidationMessage = false;
        this.closeModalIfForbiddenError(error);
        this.isDuplicateWashRequest(error);
        this.setIsProcessing(false);
        throw error;
      }
    );
  }

  private buildServicePlanObject(selectedServicePlan) {
    if (
      selectedServicePlan &&
      selectedServicePlan.planName &&
      selectedServicePlan.durationMinutes &&
      selectedServicePlan.planID
    ) {
      this.servicePlanObject.servicePlanName = selectedServicePlan.planName;
      this.servicePlanObject.servicePlanDurationMinutes =
        selectedServicePlan.durationMinutes;
      this.servicePlanObject.servicePlanID = selectedServicePlan.planID;
    }
  }

  private checkIfServicePlanChanged(washRequest: WashRequest) {
    return washRequest.servicePlan.servicePlanID ===
      washRequest.recommendedServicePlan.servicePlanID
      ? false
      : true;
  }

  private setServicePlanDataToWashRequestQuala(washRequest: WashRequest) {
    const selectedServicePlan = this.washType.value;
    delete this.servicePlanObject.servicePlanOptions;

    washRequest.recommendedServicePlan = this.recommendedServicePlanData;

    // If user selected 'Yes' on toggle
    if (this.wasWashTypeChanged.value) {
      this.buildServicePlanObject(selectedServicePlan);
      washRequest.servicePlan = this.servicePlanObject;

      // Double check if user selected a different Service Plan than the recommended
      washRequest.wasServicePlanChanged =
        this.checkIfServicePlanChanged(washRequest);
      washRequest.reasonOfChange = this.reasonOfChange.value.displayName;
      washRequest.reasonOfChangeCode = this.reasonOfChange.value.code;
      return washRequest;
    }

    // If user didn't select the toggle,
    // set the recommended service plan as the selected
    washRequest.servicePlan = this.recommendedServicePlanData;
    washRequest.wasServicePlanChanged = this.wasWashTypeChanged.value;
    return washRequest;
  }

  private setServicePlanDataToWashRequestDispatcher(washRequest: WashRequest) {
    const selectedServicePlan = this.washType.value;
    delete this.servicePlanObject.servicePlanOptions;

    this.buildServicePlanObject(selectedServicePlan);
    washRequest.servicePlan = this.servicePlanObject;
    washRequest.recommendedServicePlan = this.recommendedServicePlanData;

    // Check if user selected a different Service Plan than the recommended
    washRequest.wasServicePlanChanged =
      this.checkIfServicePlanChanged(washRequest);
    if (washRequest.wasServicePlanChanged) {
      washRequest.reasonOfChange =
        reasonOfChangeList.createdByDispatcher.displayName;
      washRequest.reasonOfChangeCode =
        reasonOfChangeList.createdByDispatcher.code;
    }
    return washRequest;
  }

  private setServicePlanDataToWashRequestWhenError(washRequest: WashRequest) {
    if (this.washType.value && this.washType.value.planName) {
      const servicePlanObject = {
        servicePlanName: this.washType.value.planName,
        servicePlanDurationMinutes: this.washType.value.planDuration,
      };
      washRequest.servicePlan = servicePlanObject;
    } else {
      washRequest.servicePlan = this.washType.value;
    }

    washRequest.wasServicePlanChanged = false;
    return washRequest;
  }

  private trimRequestAttributes(washRequest: WashRequest) {
    washRequest.poNumber = washRequest.poNumber.trim();
    return washRequest;
  }

  private isDuplicateWashRequest(error) {
    if (error.response.status === 409) {
      this.toastr.error(
        '',
        'There is another request for this tank for the same day'
      );
    }
  }

  private closeModalIfForbiddenError(error) {
    if (error.response.status === 403) {
      this.cancel();
      this.toastr.error('', 'Error creating the request!');
    }
  }

  private removeNullProducts(washRequest: WashRequest, fields: string[]) {
    fields.forEach((field) => {
      if (washRequest[field] === null) {
        delete washRequest[field];
      }
    });
  }

  cancel() {
    this.toastr.clear(this.commonProductToastr?.id);
    this.washListService.closeCreateForm();
  }

  getServicePlanData() {
    this.recommendedWashType.setValue(null);
    this.wasWashTypeChanged.setValue(false);
    this.wasWashTypeChanged.enable();
    this.washType.setValue(null);
    this.washType.enable();
    this.reasonOfChange.setValue(null);

    this.canSubmitRequest = true;
    this.actionDisclaimer = '';
    this.servicePlanObject = null;
    this.servicePlanList = [];

    const washRequest = new WashRequest({
      terminalId: this.warehouseId.value,
      lastContainedProduct1Id: this.lastContained1Id.value,
      lastContainedProduct2Id: this.lastContained2Id.value,
      lastContainedProduct3Id: this.lastContained3Id.value,
      lastContainedProductComp1Id: this.lastContainedProductComp1Id.value,
      lastContainedProductComp2Id: this.lastContainedProductComp2Id.value,
      lastContainedProductComp3Id: this.lastContainedProductComp3Id.value,
      lastContainedProductComp4Id: this.lastContainedProductComp4Id.value,
      lastContainedProductComp5Id: this.lastContainedProductComp5Id.value,
      tankTypeId: this.containerTypeId,
      operatedById: this.customerId.value,
      serviceTypeId: this.obServiceTypeId,
      tankId: this.containerId,
      foodGrade: !!this.foodGrade.value,
      kosher: !!this.kosher.value,
      exteriorWash: !!this.exteriorWash.value,
      serviceTypeOntraxId: this.serviceTypeId.value,
    });

    // API not allow null values
    const fieldsToVerify = [
      'lastContainedProduct1Id',
      'lastContainedProduct2Id',
      'lastContainedProduct3Id',
      'lastContainedProductComp1Id',
      'lastContainedProductComp2Id',
      'lastContainedProductComp3Id',
      'lastContainedProductComp4Id',
      'lastContainedProductComp5Id',
    ];

    this.removeNullProducts(washRequest, fieldsToVerify);

    if (washRequest.hasServiceRequestData) {
      this.lookingForServicePlan(true);
      this.changeFieldsStatus(ServicePlanRelatedFields, 'disable');
      this.washListService
        .getServicePlanData(washRequest)
        .then((spData: ServicePlan) => {
          this.handleServicePlanResponse(spData);
        })
        .catch((error) => {
          this.handleServicePlanResponseError(error);
        })
        .finally(() => {
          this.lookingForServicePlan(false);
          this.changeFieldsStatus(ServicePlanRelatedFields, 'enable');
        });
    }
  }

  private setProductLineQuantityOnControl(
    servicePlan,
    customerProductName,
    formControl
  ) {
    const productLines = servicePlan && servicePlan.productLines;

    if (productLines) {
      const productLine = productLines.find(
        (productLine) => productLine.customerProductName === customerProductName
      );
      const quantity = productLine ? productLine.quantity : 0;
      formControl.setValue(quantity);
    }
  }

  private handleServicePlanResponse(spData: ServicePlan) {
    this.servicePlanObject = spData;
    this.servicePlanList = spData.servicePlanOptions.map((element) => {
      element.planName = htmlDecode(element.planName);
      return element;
    });

    this.recommendedWashType.setValue(this.servicePlanObject.servicePlanName);

    this.setProductLineQuantityOnControl(
      this.servicePlanObject,
      'Hose Clean',
      this.hosesQty
    );
    this.setProductLineQuantityOnControl(
      this.servicePlanObject,
      'Pump Clean',
      this.pumpQty
    );
    this.setProductLineQuantityOnControl(
      this.servicePlanObject,
      'Fitting Clean',
      this.fittingsQty
    );

    // Store the recommended Service Plan
    this.recommendedServicePlanData = { ...spData };
    delete this.recommendedServicePlanData.servicePlanOptions;

    this.simplifiedServicePlan = this.buildSimplifiedServicePlan(
      this.recommendedServicePlanData
    );
    this.washType.setValue(this.simplifiedServicePlan);
    this.flareRequired =
      this.servicePlanObject.flareInfo.flareRequired || false;
  }

  private handleServicePlanResponseError(error: {
    response: { status: number; data: any };
  }) {
    const responseServicePlan = error.response.data;

    if (!this.hasQualaRole) {
      this.washType.disable();
      if (responseServicePlan.servicePlanName) {
        this.simplifiedServicePlan =
          this.buildSimplifiedServicePlan(responseServicePlan);
        this.washType.setValue(this.simplifiedServicePlan);
        return;
      }
      this.washType.setValue({
        planName: StandardServicePlanNames.createdByDispatcher,
        planDuration: StandardServicePlanDuration.default,
      });
      return;
    }

    if (responseServicePlan.servicePlanName) {
      // If the dummy service plan is returned, should set as the selected and the recommended
      // allowing the user to proceed with the request
      this.warningIfUnknownError(responseServicePlan);
    } else if (error.response.status === 400) {
      // If returns error 400 it means:
      // - Permit error or Restricted Content
      // - DO NOT CLEAN; DO NOT HEAT;
      // - no Plan found for DO NOT CLEAN _OR_ DO NOT HEAT;
      this.warningCreationIfRestrictedError(responseServicePlan.message);
    } else {
      // If an error 404 or unknown error has happened
      // Block the creation
      this.blockCreationIfError();
    }
  }

  private buildSimplifiedServicePlan(servicePlanData: ServicePlan) {
    if (
      servicePlanData &&
      servicePlanData.servicePlanName &&
      servicePlanData.servicePlanDurationMinutes
    ) {
      return {
        planName: servicePlanData.servicePlanName,
        durationMinutes: servicePlanData.servicePlanDurationMinutes,
        planID: servicePlanData.servicePlanID,
      };
    }
  }

  private blockCreationIfError() {
    this.recommendedWashType.setValue(
      StandardServicePlanNames.servicePlanNotFound
    );
    this.toastr.warning(
      'Please review the Container, and Product Name.',
      'Unable to get a Service Plan.'
    );
    this.actionDisclaimer =
      'Please review the following information: Container, and Product Name.\n' +
      'If the desired service plan does not appear, it means that a Service Plan has not been added for this content.\n' +
      'It is recommended to save this request as a Draft and contact EHS to get a service plan added.';
    this.wasWashTypeChanged.disable();
    this.canSubmitRequest = false;
  }

  private warningIfUnknownError(standardServicePlan: ServicePlan) {
    this.recommendedWashType.setValue(StandardServicePlanNames.createdByQuala);
    this.toastr.warning('', 'Unable to reach OpenBravo to get a Service Plan.');
    this.actionDisclaimer =
      'Unable to reach OpenBravo to get a Service Plan.\n' +
      'A Standard Service Plan will be used with 90 minutes of duration for scheduling purposes.';
    this.wasWashTypeChanged.disable();
    this.canSubmitRequest = true;

    // Set Dummy Service Plan as the selected
    this.washType.setValue(standardServicePlan);
  }

  private warningCreationIfRestrictedError(errorMessage?: string) {
    this.toastr.warning(
      errorMessage ? errorMessage : 'Products cannot be cleaned',
      'Unable to get service plan.'
    );

    if (this.hasQualaRole) {
      this.recommendedWashType.setValue(
        StandardServicePlanNames.servicePlanNotFound
      );
    } else {
      this.recommendedWashType.setValue(
        StandardServicePlanNames.createdByDispatcher
      );
    }

    if (this.hasQualaRole || this.isFoodGrade.value) {
      this.actionDisclaimer =
        `${errorMessage} You can still save this request as Draft`
          .split('. ')
          .join('.\n');
    }

    this.wasWashTypeChanged.disable();
    this.canSubmitRequest = false;
  }

  displayServicePlanOverlay(
    type: string,
    disclaimerText: string,
    washRequest: WashRequest,
    canSubmit: boolean,
    customerComments?: string,
    averageAutomatedConversionTime?: string
  ) {
    let actionHeader: string;
    let actionLabel: string;
    let secondaryActionLabel: string;
    let actionDisclaimer: string;

    if (type.toLowerCase() === 'submitted') {
      actionHeader = popUpHeaders.submit;
      actionLabel = popUpLabels.submit;
      actionDisclaimer = disclaimerText || popUpDisclaimers.submit;
    } else {
      actionHeader = popUpHeaders.accept;
      actionLabel = popUpLabels.accept;
      actionDisclaimer = disclaimerText || popUpDisclaimers.accept;
      secondaryActionLabel = popUpLabels.acceptWithoutOrderConversion;
    }

    const dialogRef = this.dialog.open<
      ServicePlanPopUpOverlayComponent,
      ServicePlanPopUpInputModel
    >(ServicePlanPopUpOverlayComponent, {
      data: {
        actionHeader,
        actionLabel,
        secondaryActionLabel,
        actionDisclaimer,
        washRequest,
        canSubmit,
        customerComments,
        averageAutomatedConversionTime,
      },
      panelClass: 'service-plan-popup-overlay',
    });

    dialogRef.beforeClosed().subscribe((overlayData) => {
      if (overlayData && overlayData.submit) {
        if (overlayData.preventOrderConversion) {
          washRequest.preventOrderConversion =
            overlayData.preventOrderConversion;
        }

        this.createWashRequest(washRequest);
      } else {
        this.setIsProcessing(false);
      }
    });
  }

  handleExteriorWashToggle(selectedTank) {
    const exteriorWashProduct =
      this.washListService.dataStore.exteriorWashProducts.find(
        (item) => item.containerTypeId === selectedTank.containerTypeId
      );
    const exteriorWashOnlyServiceId =
      this.washListService.dataStore.serviceTypes.find(
        (item) => item.key === ServiceTypeKeys.ExteriorWashOnly
      ).id;

    if (!exteriorWashProduct) {
      this.exteriorWash.setValue(false);
      this.exteriorWash.disable();
      this.washListService.toggleServiceTypeOptions(false, [
        ServiceTypeKeys.ExteriorWashOnly,
      ]);
      if (this.serviceTypeId.value === exteriorWashOnlyServiceId) {
        const cleanServiceId = this.washListService.dataStore.serviceTypes.find(
          (item) => item.key === ServiceTypeKeys.Clean
        ).id;
        this.serviceTypeId.setValue(cleanServiceId);
      }
    } else {
      this.washListService.toggleServiceTypeOptions(true);
      if (this.serviceTypeId.value === exteriorWashOnlyServiceId) {
        this.exteriorWash.setValue(true);
        this.exteriorWash.disable();
      } else {
        this.exteriorWash.enable();
      }
    }
  }

  cleanBillToTerminal() {
    this.washBillToTerminalId.reset();
  }
}
