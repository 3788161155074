import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import {
  MenuConfig,
  MenuOption,
  OptionConfig,
  SectionConfig,
} from './navigation.model';

import {
  homeSection,
  requestListSection,
  settingsSection,
  shiftLogsSection,
  bulkUploadSection,
  ncrSection,
  supportSection,
  reportsSection,
  scheduleSection,
  logOutSection,
  otherToolsSection,
  pagesWithRolePicker,
  getSectionDict,
} from './navigation.config';
import { UserType } from './user-type.enum';
import { RoleName } from './role-name.enum';

import { AuthService } from '../../core/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class NavigationService {
  private _breakpointFlag = new BehaviorSubject(true);
  currentBreakpoint = this._breakpointFlag.asObservable();
  shouldDisplaySchneiderCompletionOnMenu: boolean = false;

  constructor(
    private authService: AuthService,
    private location: Location,
    private activatedRoute: ActivatedRoute
  ) {}

  getSelectedMenu() {
    let selectedOption;
    const url = this.location.path();
    const segments = url.split('/').filter((segment) => segment.length > 0);

    for (const [key, value] of Object.entries(MenuOption)) {
      if (url.includes('non-conformity-report')) {
        const searchParams = new URLSearchParams(window.location.search);
        const segment = segments[3];
        const option =
          searchParams.get('option') || (segment && segment.toString());

        selectedOption =
          option && Object.values(MenuOption).includes(option as MenuOption)
            ? option
            : MenuOption.NcrOpened;
        return selectedOption;
      }

      if (url.includes('wash-list')) {
        selectedOption = segments[2] || MenuOption.Draft;
        return selectedOption;
      }

      if (
        segments.length > 1 &&
        value.includes(segments[segments.length - 1])
      ) {
        selectedOption = value;
        return selectedOption;
      }
    }
  }

  getMenuSectionThroughUrl(
    menuConfig: MenuConfig
  ): OptionConfig | SectionConfig | undefined {
    const homeOptions = [MenuOption.HomeFeed];
    let splittedUrl: string[] = this.location.path().split('/');

    if (splittedUrl[2]?.includes('access-denied')) {
      splittedUrl = this.activatedRoute.snapshot.queryParamMap
        .get('from')
        .split('/');
    }

    const reportsOptions = [
      MenuOption.Reports,
      MenuOption.WashMetrics,
      MenuOption.LeanDailyManagementReport,
      MenuOption.TerminalScorecardDepot,
      MenuOption.TerminalScorecardIndustrial,
      MenuOption.TerminalScorecardRail,
      MenuOption.TerminalScorecardWash,
      MenuOption.EhsWasteManagement,
    ];

    for (const path of splittedUrl) {
      const section = menuConfig.sections.find(
        (section) =>
          section.value === path ||
          (section.value === 'home' &&
            (homeOptions as string[]).includes(path)) ||
          (section.value === 'reports' &&
            (reportsOptions as string[]).includes(path))
      );

      if (section) {
        return section;
      }
    }

    return undefined;
  }

  isUserTypePresent(
    userType: UserType,
    visibleByUserType: UserType[]
  ): boolean {
    if (visibleByUserType.includes(UserType.AllUserTypes)) {
      return true;
    }

    const availableUserType = visibleByUserType.find((option) => {
      return option === userType;
    });

    return availableUserType !== undefined && availableUserType !== null;
  }

  isRolePresent(currentRole: string, authorizedRoles: RoleName[]): boolean {
    if (authorizedRoles.includes(RoleName.AllRoles)) {
      return true;
    }
    const availableRole = authorizedRoles.find((role) =>
      currentRole.includes(role)
    );
    return availableRole !== undefined && availableRole !== null;
  }

  hasRolePicker(url: string) {
    const urlClean = url.split('?')[0];

    return pagesWithRolePicker.some((page) => urlClean?.includes(page));
  }

  getRequestListAvailableSections(
    userType: UserType,
    section: SectionConfig
  ): SectionConfig {
    if (UserType.Internal === userType) {
      section = {
        ...section,
        options: section.options.filter(
          (option) => option.value !== MenuOption.SchneiderPortalCompletions
        ),
      };
    }
    return this.getAvailableSections(userType, section);
  }

  getAvailableSections(
    userType: UserType,
    section: SectionConfig | OptionConfig
  ): SectionConfig | OptionConfig {
    const isRootSectionAvailable =
      section.visibleByUserType.includes(UserType.AllUserTypes) ||
      this.isUserTypePresent(userType, section.visibleByUserType);
    const currentRole = this.authService?.user?.currentRole;

    if (!isRootSectionAvailable) {
      return;
    }

    return {
      ...section,
      options: section.options?.filter((option) => {
        const hasRole =
          this.authService.user?.roles?.length > 0 && currentRole !== undefined;

        const checks = [
          hasRole
            ? this.isRolePresent(currentRole, option.authorizedRoles)
            : this.isUserTypePresent(userType, option.visibleByUserType),
        ];

        return checks.every(Boolean);
      }),
    };
  }

  getSettingsAvailableSections(
    userType: UserType,
    section: SectionConfig,
    isTerminalActive: boolean,
    isQualaWorker: boolean
  ): SectionConfig {
    let availableSections = this.getAvailableSections(userType, section);

    if (isQualaWorker && isTerminalActive && availableSections) {
      availableSections = {
        ...availableSections,
        options: availableSections?.options?.filter(
          (option) => option.value !== MenuOption.ActivateTerminal
        ),
      };
    }

    return availableSections;
  }

  getScheduleAvailableSections(
    userType: UserType,
    section: SectionConfig,
    isTerminalActive: boolean,
    isQualaWorker: boolean
  ): SectionConfig {
    let availableSections = this.getAvailableSections(userType, section);

    if (isQualaWorker && !isTerminalActive) {
      return;
    }

    return availableSections;
  }

  getNcrAvailableSections(
    userType: UserType,
    section: SectionConfig,
    hasAllReportActions: boolean,
    isQualaWorker: boolean
  ): SectionConfig {
    if (hasAllReportActions) {
      return section;
    }

    let availableSections = this.getAvailableSections(userType, section);

    if (isQualaWorker && !hasAllReportActions) {
      availableSections = {
        ...section,
        options: section.options.filter(
          (option) => option.value !== MenuOption.NcrOpened
        ),
      };
    }

    return availableSections;
  }

  getMenuConfig(): MenuConfig {
    const menu = new MenuConfig();
    const hasAllReportActions = this.authService.hasAllReportActions();
    const isQualaWorker = this.authService.hasQualaWorkerRole();
    const isTerminalActive = this.authService?.user?.currentTerminal?.active;
    const userType = this.authService?.getUserType() as UserType;

    menu.sections.push(
      ...[
        this.getAvailableSections(userType, homeSection),
        this.getRequestListAvailableSections(userType, requestListSection),
        this.getScheduleAvailableSections(
          userType,
          scheduleSection,
          isTerminalActive,
          isQualaWorker
        ),
        this.getAvailableSections(userType, shiftLogsSection),
        this.getNcrAvailableSections(
          userType,
          ncrSection,
          hasAllReportActions,
          isQualaWorker
        ),
        this.getAvailableSections(userType, reportsSection),
        this.getAvailableSections(userType, bulkUploadSection),
        this.getSettingsAvailableSections(
          userType,
          settingsSection,
          isTerminalActive,
          isQualaWorker
        ),
        this.getAvailableSections(userType, supportSection),
        this.getAvailableSections(userType, otherToolsSection),
        this.getAvailableSections(userType, logOutSection),
      ]
    );

    menu.sections = menu.sections.filter((section) => section !== undefined);
    return menu;
  }

  getCurrentSectionOptions(section: string, menuConfig: MenuConfig) {
    let getSubmenuOptions;
    const fullSection = menuConfig.sections.find(
      (item) => item.value === section
    );

    getSubmenuOptions = {
      value: section,
      label: fullSection.label,
      options: fullSection.options,
    };

    return getSubmenuOptions;
  }
}
