import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { KeycloakService } from 'keycloak-angular';

import { ToastrService } from 'ngx-toastr';
import { skip, Subscription } from 'rxjs';

import { PageTitle } from './shared/navigation/navigation.model';

import { SystemService } from './core/system.service';
import { ConnectivityService } from './core/services/connectivity.service';
import { AuthService } from './core/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private systemServiceSubscription: Subscription;
  private isUserOnlineSubscription: Subscription;
  currentRoute: ActivatedRouteSnapshot;

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private toastr: ToastrService,
    private systemService: SystemService,
    private connectivityService: ConnectivityService,
    private title: Title,
    private updates: SwUpdate,
    private keycloak: KeycloakService,
    private authService: AuthService,
    library: FaIconLibrary
  ) {
    library.addIconPacks(fas);
    this.currentRoute = this.getCurrentRoute();
  }

  ngOnInit(): void {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe((evt) => {
        if (evt.type !== 'VERSION_READY') {
          return;
        }

        if (confirm('New version available. Load New Version?')) {
          window.location.reload();
        }
      });
    }

    this.systemServiceSubscription = this.systemService.maintenance.subscribe(
      (hasMaintenance: boolean) => {
        if (hasMaintenance) {
          this.router.navigate(['/system-maintenance']);
        }
      }
    );

    // set Page Title and push GTM data layer for every visited page
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.currentRoute = this.getCurrentRoute();

        if (!this.connectivityService.isOnline) {
          return;
        }

        let key = item.url.split('/').pop();
        if (!key) {
          key = 'default';
        }
        const isNCRPage = item.url.includes('non-conformity-report');
        const isExterior = item.url.includes('exterior-wash');
        this.setPageTitle(key, isNCRPage, isExterior);
        const gtmTag = {
          event: 'pageview',
          dp: item.url,
          dh: window.location.host,
        };

        this.gtmService.pushTag(gtmTag);
      }
    });

    let previousMissingKeycloakReloadToast;

    this.isUserOnlineSubscription = this.connectivityService.isOnlineObservable
      .pipe(skip(1))
      .subscribe(async (isUserOnline) => {
        const supportsOfflineUsage =
          this.currentRoute.data['supportsOfflineUsage'];
        const isKeycloakUser = await this.authService.isKeycloakUser();

        if (
          !isUserOnline &&
          (!supportsOfflineUsage || isKeycloakUser) &&
          !this.router.url.startsWith('/no-connection')
        ) {
          this.router.navigate(['/no-connection'], {
            queryParams: { 'previous-url': this.router.url },
          });
        }

        if (isUserOnline) {
          this.toastr.success("You're back online!");
        } else {
          this.toastr.warning("You're offline.");
        }

        if (isUserOnline && !this.keycloak.getKeycloakInstance()) {
          previousMissingKeycloakReloadToast =
            this.showMissingKeycloakReloadToast(
              previousMissingKeycloakReloadToast
            );
        } else if (previousMissingKeycloakReloadToast) {
          previousMissingKeycloakReloadToast.toastRef.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.systemServiceSubscription.unsubscribe();
    this.isUserOnlineSubscription.unsubscribe();
  }

  private setPageTitle(
    key: string,
    isNCRPage: boolean,
    isExterior: boolean
  ): void {
    if (isNCRPage) {
      switch (key) {
        case 'opened':
          key = 'ncrOpened';
          break;
        case 'in-analysis':
          key = 'ncrInAnalysis';
          break;
        case 'waiting-for-approval':
          key = 'ncrWaitingForApproval';
          break;
        case 'completed':
          key = 'ncrCompleted';
          break;
        case 'canceled':
          key = 'ncrCanceled';
          break;
        default:
          key = key.includes('create') ? 'ncrCreation' : '';
          break;
      }
    } else if (isExterior) {
      if (key.includes('accept')) {
        key = 'exteriorWashAccept';
      } else if (key.includes('decline')) {
        key = 'exteriorWashDecline';
      } else {
        key = 'exteriorWashOffer';
      }
    } else {
      switch (key) {
        case 'external-ncr':
          key = 'ncrCreation';
          break;
        case 'user-notification':
          key = 'userNotification';
          break;
        case 'business-partner-notification':
          key = 'businessPartnerNotification';
          break;
        case 'exterior-wash-offer':
          key = 'exteriorWashOffer';
          break;
        default:
          break;
      }
    }
    this.title.setTitle(PageTitle[key]);
  }

  private getCurrentRoute(): ActivatedRouteSnapshot {
    let currentRoute = this.router.routerState.snapshot.root;

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    return currentRoute;
  }

  private showMissingKeycloakReloadToast(
    previousMissingKeycloakReloadToast: any
  ): any {
    const classes = 'reload-toast--reload-page-link';
    const reloadLink = `<a href="#" class="${classes}" style="text-decoration: underline; color: #05347a;">reload the page</a>`;

    const toastMessage = `You're online, but to ensure full access to OnTrax, you need to ${reloadLink}.`;

    const toast = this.toastr.warning(toastMessage, '', {
      enableHtml: true,
      disableTimeOut: true,
      tapToDismiss: false,
    });

    setTimeout(() => {
      const link = document.querySelector(`.${classes}`);
      link?.addEventListener('click', (event) => {
        event.preventDefault();
        window.location.reload();
      });
    });

    if (previousMissingKeycloakReloadToast) {
      previousMissingKeycloakReloadToast.toastRef.close();
    }

    return toast;
  }
}
