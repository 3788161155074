import { UntypedFormControl } from '@angular/forms';

export enum ButtonStyle {
  PRIMARY = 'button-primary',
  SECONDARY = 'button-secondary',
  DANGER = 'button-danger',
  DARK = 'button-dark',
  LIGHT = 'button-light',
}

export interface Button {
  btnText: string;
  btnDisabled?: boolean;
  btnClick?: (...args) => any;
  btnId?: string;
  buttonStyle?: ButtonStyle;
  icon?: string;
  isProcessing?: boolean;
  class?: string;
  tooltip?: string;
  disabledTooltip?: any;
  routerLink?: string[];
  queryParams?: any;
  [key: string]: any;
}

export interface ActionsBarProperties {
  centralizeComponents?: boolean;
  label?: string;
  buttons: Button[];
  toggleLabel?: string;
  isToggled?: UntypedFormControl;
  showToggle?: boolean;
  toggleId?: string;
  onToggleChange?: (checked: boolean) => void;
}

export const findIdContainsMatches = (
  searchId: string,
  buttons: Button[]
): Button[] => {
  return buttons.filter((button) => button.btnId?.includes(searchId) ?? false);
};

export const findExactIdMatches = (
  searchId: string,
  buttons: Button[]
): Button[] => {
  return buttons.filter((button) => button.btnId === searchId);
};

export const updateProcessingStatusAndUpdateDisabled = (
  searchId: string,
  buttons: Button[],
  isProcessing: boolean,
  isProcessingName?: string
): void => {
  buttons.forEach((button) => {
    if (button.btnId?.includes(searchId)) {
      button.isProcessing = isProcessing;
    }
    button.buttonDisabledParameter === isProcessingName
      ? (button.btnDisabled = isProcessing)
      : null;
  });
};

export const setIsProcessingForAllButtons = (
  isProcessing: boolean,
  buttons: Button[]
) => {
  return buttons.map((button) => ({
    ...button,
    isProcessing,
  }));
};
