// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-and-conditions-container {
  align-items: center;
}
.terms-and-conditions-container .title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: start;
}
.terms-and-conditions-container .text {
  text-align: start;
}
.terms-and-conditions-container .li {
  font-weight: bold;
}

.settings-component {
  display: grid;
  grid-template-rows: auto auto;
  padding: 0 80px 0 32px;
}

.form-section {
  border-radius: 16px;
  background-color: white;
  padding: 15px;
  margin: -10px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/terms-conditions/terms-conditions.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAGE;EACE,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,iBAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;;AAOA;EACE,aAAA;EACA,6BAAA;EACA,sBAAA;AAJF;;AAOA;EACE,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,aAAA;AAJF","sourcesContent":["@import '../../../variables.scss';\n\n.terms-and-conditions-container {\n  align-items: center;\n\n  .title {\n    margin-bottom: 1rem;\n    font-size: 1.5rem;\n    font-weight: bold;\n    text-align: start;\n  }\n\n  .text {\n    text-align: start;\n  }\n\n  .li {\n    font-weight: bold;\n  }\n}\n\n.settings-component {\n  display: grid;\n  grid-template-rows: auto auto;\n  padding: 0 80px 0 32px;\n}\n\n.form-section {\n  border-radius: 16px;\n  background-color: white;\n  padding: 15px;\n  margin: -10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
