import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../navigation.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/localstorage.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent implements OnInit {
  showRolePicker: boolean = false;

  @Input() userName: string;
  @Input() hasQualaRole: boolean;

  constructor(
    private router: Router,
    private menuService: NavigationService
  ) {}

  ngOnInit(): void {
    this.showRolePicker = this.menuService.hasRolePicker(this.router.url);
    this.router.events.subscribe(() => {
      this.showRolePicker = this.menuService.hasRolePicker(this.router.url);
    });
  }
}
