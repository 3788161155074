import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quala-button',
  templateUrl: './quala-button.html',
  styleUrls: ['./quala-button.scss'],
})
export class QualaButtonComponent implements OnInit {
  @Input() btnText?: string;
  @Input() isProcessing: boolean;
  @Input() btnDisabled: boolean;
  @Input() disabledTooltip: string;
  @Input() icon: string;
  @Input() router: string;
  @Input() queryParams: string;
  @Input() buttonStyle:
    | 'button-light'
    | 'button-primary'
    | 'button-secondary'
    | 'button-danger'
    | 'button-dark';
  @Input() btnId: string;
  @Input() datae2ebutton: string;
  @Input() tooltip?: string;
  @Output() btnClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  handleClick() {
    if (this.btnClick) {
      this.btnClick.emit();
    }
  }
}
